import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Icon, Button, Modal, Header, Form, Image, Message,
} from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { signupTeammate } from '../../../redux/actions/main';
import { doesTeammateHaveTeams } from '../../../redux/actions/team';
import watercoolerImage from '../../../styles/images/logo.png';

const JoinForm = ({
  currentUser, currentUserError, hasTeams, signupTeammate, doesTeammateHaveTeams,
}) => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [emailAddress, setEmailAddress] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [signingUp, setSigningUp] = useState('');

  useEffect(() => {
    if (currentUserError) {
      setSigningUp(false);
      switch (currentUserError.code) {
        case 'auth/wrong-password':
          setErrorMessage('The password entered is incorrect');
          break;
        case 'auth/user-not-found':
          setErrorMessage('No account found for this email address');
          break;
        case 'auth/email-already-in-use':
          setErrorMessage(currentUserError.message);
          break;
        default:
          setErrorMessage('Unable to login at this time');
      }
    }

    if (currentUser) {
      history.push('/dashboard');
    }
  }, [history, currentUser, currentUserError]);

  useEffect(() => {
    if (hasTeams) {
      if (password === confirmPassword) {
        setSigningUp(true);
        signupTeammate({ name, email: emailAddress, password });
      } else {
        setErrorMessage('The passwords provided do not match');
      }
    } else if (emailAddress) {
      setErrorMessage('Your email address has no teams associated.');
    }
  }, [confirmPassword, password, emailAddress, name, hasTeams, signupTeammate]);

  const signupUser = async () => {
    doesTeammateHaveTeams(emailAddress);
  };

  return (
    <Form>
      <Modal
        open
        basic
        dimmer
        size="mini"
      >
        <Header icon>
          <div style={{
            display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', color: 'white',
          }}
          >
            <div>
              <Image src={watercoolerImage} size="small" wrapped />
              <div style={{ paddingTop: 20 }}><i>Join a team and have fun!</i></div>
            </div>
          </div>
        </Header>
        {errorMessage && <Message error content={errorMessage} />}

        <Modal.Content style={{ display: 'flex', flex: 1 }}>
          <>
            <Form style={{ flex: 1 }} inverted onSubmit={() => signupUser()}>
              <Form.Group>
                <Form.Input
                  width={16}
                  inverted
                  label="Name"
                  name="name"
                  value={name}
                  onChange={(e, d) => setName(d.value)}
                  placeholder="Enter your name..."
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={16}
                  inverted
                  label="Email"
                  name="email"
                  value={emailAddress}
                  onChange={(e, d) => setEmailAddress(d.value)}
                  placeholder="Enter your email..."
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={16}
                  label="Password"
                  name="password"
                  type="password"
                  inverted
                  value={password}
                  onChange={(e, d) => setPassword(d.value)}
                  placeholder="Enter your password..."
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={16}
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  inverted
                  value={confirmPassword}
                  onChange={(e, d) => setConfirmPassword(d.value)}
                  placeholder="Enter your password again..."
                />
              </Form.Group>
              <Form.Group>
                <Button color="green" fluid loading={signingUp} disabled={signingUp} onClick={() => signupUser()}>
                  <Icon name="checkmark" />
                  Signup!
                </Button>

              </Form.Group>
              <Form.Group>
                <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                  Already have an account?&nbsp;
                  <a href="/login">Login!</a>
                </div>
              </Form.Group>

            </Form>
          </>
        </Modal.Content>
      </Modal>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const {
    main: { currentUser, currentUserError },
    team: { hasTeams },
  } = state;

  return { currentUser, currentUserError, hasTeams };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  { signupTeammate, doesTeammateHaveTeams },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(JoinForm);
