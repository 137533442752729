import React from 'react';
import { Image } from 'semantic-ui-react';
import watercoolerImage from '../../styles/images/watercooler.png';

const TourWelcome = ({
  text, bullets,
}) => (
  <div style={{
    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItem: 'center', flex: 1, height: '100%', padding: 20,
  }}
  >
    <div style={{ alignSelf: 'center' }}>
      <Image src={watercoolerImage} style={{ height: 90, width: 70 }} />
    </div>
    <div style={{ fontSize: 16, textAlign: 'center' }}>
      {text}
    </div>
    <div style={{ fontSize: 16, textAlign: 'left', paddingLeft: 10 }} nowrap>
      {bullets && bullets.map(bullet => (
        <div>
          &bull;
          {bullet}
        </div>
      ))}
    </div>
  </div>
);

export default TourWelcome;
