import React from 'react';
import Avatar from 'react-avatar';
import useStyles from './styles';

const Person = ({ teammate, showEmail = true }) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.avatarContainer}>
        <Avatar size={30} round src={teammate.avatar} name={teammate.name} />
      </div>
      <div className={styles.nameContainer}>
        <h4 className={styles.personName}>{teammate.name}</h4>
        {showEmail && <span className={styles.personEmail}>{teammate.email}</span>}
      </div>
    </div>
  );
};

export default Person;
