import { createUseStyles } from 'react-jss';

export default createUseStyles({
  content: {
    paddingTop: 50,
  },
  container: {
    height: '100%',
  },
});
