import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    backgroundImage: 'linear-gradient(to right, #99cfff, #66b8ff)',
    height: '110vh',
  },
  grid: {
    backgroundImage: 'linear-gradient(to right, #99cfff, #66b8ff)',
  },
  column: {
    paddingTop: 200,
  },
});
