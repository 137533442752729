import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Icon, Button, Modal, Header, Form,
} from 'semantic-ui-react';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';

const ChangePassword = ({
  show, setShow, currentUser,
}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handlePasswordChange = () => {
    if (password === confirmPassword) {
      currentUser.updatePassword(password).then(() => {
        toast({ title: 'Password Updated Successful', description: <p>Your password has been updated</p> });
        setTimeout(() => setShow(false), 3000);
        // setShow(false);
      }).catch((error) => {
        toast({ title: 'Password Update Failed', description: <p>{error.message}</p> });
      });
    } else {
      toast({ title: 'Password Update Failed', description: <p>The password and confirm password do not match.</p> });
    }
  };

  return (
    <Form>

      <Modal
        onClose={() => setShow(false)}
        onOpen={() => setShow(true)}
        open={show}
        size="small"
        basic
      >
        <SemanticToastContainer />
        <Header icon>
          Change your password
        </Header>
        <Modal.Content style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
          <Form inverted style={{ width: '50%' }}>
            <Form.Group>
              <Form.Input
                width={16}
                inverted
                fluid
                label="Password"
                name="password"
                autoComplete="off"
                type="password"
                value={password}
                onChange={(e, data) => setPassword(data.value)}
                placeholder="Enter your new password..."
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                width={16}
                inverted
                label="Confirm Password"
                name="confirmPassword"
                autoComplete="off"
                type="password"
                value={confirmPassword}
                onChange={(e, data) => setConfirmPassword(data.value)}
                placeholder="Confirm your new password..."
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="green" onClick={handlePasswordChange}>
            <Icon name="checkmark" />
            Reset Password
          </Button>
        </Modal.Actions>
      </Modal>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const {
    main: { currentUser },
  } = state;

  return { currentUser };
};

export default connect(mapStateToProps, null)(ChangePassword);
