import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Icon, Button, Modal, Header, Form, Dropdown,
} from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import { saveEvent, removeEvent } from '../../../redux/actions/schedule';
import { getRandomQuestion } from '../../../redux/actions/question';
import { convertFirebaseTimestampToDate } from '../../../api/helpers';

const EventForm = ({
  removeEvent, event, selectedTeam, teammates, settings, saveEvent, setShow, show, teammateInfo,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [participantList, setParticipantList] = useState([]);
  const [name, setName] = useState('');
  const [question, setQuestion] = useState('');
  const [eventDate, setEventDate] = useState(moment().toDate());

  const handleDelete = () => {
    removeEvent(event);
  };

  useEffect(() => {
    if (!_.isEmpty(event)) {
      setName(event?.name);
      setEventDate(event?.eventDate ? convertFirebaseTimestampToDate(event.eventDate, settings.timezone).format('YYYY-MM-DDTHH:mm:ss') : moment().format('YYYY-MM-DDTHH:mm:ss'));
      setQuestion(event?.question);
      setParticipantList(event?.participants);
    } else {
      setName('Watercooler Event');
      setEventDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
      setQuestion('');
      if (selectedTeam) {
        getRandomQuestion(selectedTeam.id).then(question => setQuestion(question.data));
      }
      if (teammates) {
        setParticipantList(teammates.map(teammate => teammate.id));
      }
    }
  }, [selectedTeam, settings, teammates, event]);

  const buildTeammateList = () => {
    if (teammates && teammates.length) {
      return teammates.filter(teammate => teammate.approved).map(teammate => ({
        key: teammate.id,
        text: teammate.name,
        value: teammate.id,
      }));
    }
    return [];
  };

  const handleSubmit = () => {
    const data = {
      id: event?.id,
      name,
      eventDate,
      question,
      teamId: selectedTeam.id,
      participants: participantList,
    };
    saveEvent(data);
    setShow(false);
  };

  return (
    <Form>
      <Modal
        onClose={() => setShow(false)}
        onOpen={() => setShow(true)}
        open={show}
        size="small"
      >
        <Header icon>
          <div style={{
            display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center',
          }}
          >
            <div>
              <Icon name="calendar plus outline" />
            </div>
            <div>
              {_.isEmpty(event) ? 'Add Event' : 'Update Event'}
            </div>
          </div>
        </Header>
        <Modal.Content style={{ display: 'flex', flex: 1 }}>
          <>
            <Form style={{ flex: 1 }} onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Input
                  width={16}
                  label="Name"
                  name="name"
                  value={name}
                  required
                  onChange={(e, d) => setName(d.value)}
                  placeholder="name"
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={16}
                  label="Date"
                  name="eventDate"
                  required
                  value={eventDate}
                  placeholder="Date"
                  onChange={(e, d) => setEventDate(d.value)}
                  type="datetime-local"
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={16}
                  label="Question"
                  name="question"
                  required
                  value={question}
                  loading={_.isEmpty(question)}
                  placeholder="Question"
                  onChange={(e, d) => setQuestion(d.value)}
                />
              </Form.Group>
              <Form.Group>
                <Dropdown
                  placeholder="Teammates"
                  name="participants"
                  fluid
                  multiple
                  search
                  value={participantList}
                  onChange={(e, data) => setParticipantList(data.value)}
                  selection
                  options={buildTeammateList()}
                />
              </Form.Group>
            </Form>
          </>
        </Modal.Content>
        <Modal.Actions>
          {event?.id && (
          <Button floated="left" color="red" onClick={() => setShowDeleteModal(true)}>
            <Icon name="delete" />
            {' '}
            Delete
          </Button>
)}
          <Button basic color="red" onClick={() => setShow(false)}>
            <Icon name="remove" />
            {' '}
            Cancel
          </Button>
          <Button color="green" type="submit" onClick={handleSubmit}>
            <Icon name="checkmark" />
            {' '}
            Save
          </Button>
        </Modal.Actions>
        <Modal
          basic
          open={showDeleteModal}
          size="small"
        >
          <Header icon>
            <Icon name="user delete" />
            Delete
            {' '}
            {teammateInfo?.name}
          </Header>
          <Modal.Content>
            <p>
              Are you sure you want to remove this event from your schedule?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="red" inverted onClick={() => setShowDeleteModal(false)}>
              <Icon name="remove" />
              {' '}
              No
            </Button>
            <Button
              color="green"
              type="submit"
              inverted
              onClick={() => {
                handleDelete();
                setShowDeleteModal(false);
                setShow(false);
              }}
            >
              <Icon name="checkmark" />
              {' '}
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      </Modal>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const {
    team: { selectedTeam, teammates },
    settings: { settings },
  } = state;

  return { selectedTeam, teammates, settings };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  { saveEvent, removeEvent },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(EventForm);
