import React from 'react';
import Particles from 'react-tsparticles';

const WaterCoolerParticles = ({
  background,
}) => (
  <Particles
    id="tsparticles"
    height="100vh"
    width="100vw"
    options={{
      background: {
        color: {
          value: background || '#0561ff',
        },
      },
      fpsLimit: 100,
      motion: {
        reduce: { disable: true },
      },
      interactivity: {
        detectsOn: 'canvas',
        events: {
          onClick: {
            enable: true,
            mode: 'push',
          },
          onHover: {
            enable: true,
            mode: 'repulse',
          },
          resize: true,
        },
        modes: {
          bubble: {
            distance: 400,
            duration: 2,
            opacity: 0.8,
            size: 40,
          },
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: '#ffffff',
        },
        links: {
          color: '#ffffff',
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        collisions: {
          enable: false,
        },
        move: {
          direction: 'none',
          enable: true,
          outMode: 'bounce',
          random: false,
          speed: 6,
          straight: false,
        },
        number: {
          density: {
            enable: true,
            value_area: 800,
          },
          value: 80,
        },
        opacity: {
          value: 1,
        },
        shape: {
          type: 'circle',
        },
        size: {
          random: true,
          value: 10,
        },
      },
      detectRetina: true,
    }}
  />
);

export default WaterCoolerParticles;
