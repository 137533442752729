/* eslint-disable react/no-danger */
import React from 'react';
import { LightenDarkenColor } from 'lighten-darken-color';
import {
  Icon, Item, Label, Card,
} from 'semantic-ui-react';

const SubscriptionSelection = ({
  subscription, products, handleSubscriptionSelection,
}) => {
  const getBackgroundColor = selected => (subscription === selected ? LightenDarkenColor('#CCCCCC', 100) : LightenDarkenColor('#CCCCCC', 40));

  const getBoxShadow = selected => (subscription === selected ? '0 0 0 3px #b6d7a8,0 2px 4px 0 rgba(34,36,38,.42),0 2px 20px 0 rgba(34,36,38,.55)' : '0 0 0 1px #d4d4d5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 2px 0 rgba(34,36,38,.15)');

  const getTop = selected => (subscription === selected ? -10 : 0);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {products && products.map(product => (
        <Card
          key={product.id}
          name={product.id}
          raised={subscription === product.id}
          onClick={handleSubscriptionSelection}
          style={{
            marginTop: 0,
            marginLeft: 5,
            marginRight: 5,
            marginBottom: 0,
            backgroundColor: getBackgroundColor(product.id),
            boxShadow: getBoxShadow(product.id),
            top: getTop(product.id),
          }}
        >
          <Card.Content textAlign="center">
            <Card.Header>{product.metadata.title}</Card.Header>
            <Card.Meta>{product.metadata.subtitle}</Card.Meta>
            {product.metadata.bestDeal && (
            <Label
              style={{
                position: 'absolute', right: -97, left: 'auto', top: 0,
              }}
              as="div"
              color="green"
              ribbon="right"
            >
              Best deal!
            </Label>
            )}

          </Card.Content>
          <Card.Content>
            <Item.Group>
              <Item>
                <Item.Content>
                  <Item.Description>
                    <Icon name="angle right" color="green" />
                    <span dangerouslySetInnerHTML={{ __html: product.metadata.line1 }} />
                  </Item.Description>
                </Item.Content>
              </Item>
              <Item>
                <Item.Content>
                  <Item.Description>
                    <Icon name="angle right" color="green" />
                    <span dangerouslySetInnerHTML={{ __html: product.metadata.line2 }} />
                  </Item.Description>
                </Item.Content>
              </Item>
              <Item>
                <Item.Content>
                  <Item.Description>
                    <Icon name="angle right" color="green" />
                    <span dangerouslySetInnerHTML={{ __html: product.metadata.line3 }} />
                  </Item.Description>
                </Item.Content>
              </Item>
            </Item.Group>
          </Card.Content>
        </Card>
      ))}
    </div>
  );
};

export default SubscriptionSelection;
