import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Icon, Modal, Header, Form, Image, Message,
} from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';
import { doesAccountExist, approveTeam } from '../../../redux/actions/team';
import watercoolerImage from '../../../styles/images/logo.png';

const ApprovalForm = ({
  approveTeam, teammateAnswer, doesAccountExist, hasAccount,
}) => {
  const history = useHistory();
  const [message, setMessage] = useState('We are updating your status...');
  const [messageHeader, setMessageHeader] = useState('Just one second');
  const [email, setEmail] = useState('');
  const [approved, setApproved] = useState(null);
  const [showSpinner, setShowSpinner] = useState(true);

  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const team = searchParams.get('team');
    const email = searchParams.get('email');
    const approval = searchParams.get('approve');
    setApproved(approval === 'true');

    if (team && email) {
      setEmail(email);
      approveTeam(email, team, (approval === 'true'));
    }
  }, [location, approveTeam]);

  useEffect(() => {
    if (teammateAnswer !== undefined && email) {
      if (teammateAnswer) {
        if (approved) {
          doesAccountExist(email);
        } else {
          setShowSpinner(false);
          setMessageHeader('');
          setMessage('Thank you for responding. We have removed you from the team.');
        }
      } else {
        setShowSpinner(false);
        setMessageHeader('');
        setMessage('The team approval was not found. Please contact support for help.');
      }
      // http://localhost:3006/teamstatus?email=aaron.boss@gmail.com&team=a9v9gh7c0NdRS5SkqPQ9&approve=true
    }
  }, [teammateAnswer, approved, doesAccountExist, email]);

  useEffect(() => {
    if (approved) {
      setShowSpinner(false);
      setMessageHeader('');
      setMessage('Approved! You will now be included in Watercooler events!');
      setTimeout(() => {
        if (hasAccount) {
          history.push('/login');
        } else {
          history.push(`/join?email=${email}`);
        }
      }, 2000);
    }
  }, [hasAccount, approved, email, history]);

  return (
    <Form>
      <Modal
        open
        basic
        dimmer
        size="mini"
      >
        <Header icon>
          <div style={{
            display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', color: 'white',
          }}
          >
            <div>
              <Image src={watercoolerImage} size="small" wrapped />
            </div>
          </div>
        </Header>
        <Message icon info floating>
          {showSpinner && <Icon name="circle notched" loading />}
          <Message.Content>
            <Message.Header>{messageHeader}</Message.Header>
            {message}
          </Message.Content>
        </Message>
      </Modal>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const {
    team: { teammateAnswer, hasAccount },
  } = state;

  return { teammateAnswer, hasAccount };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  { doesAccountExist, approveTeam },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalForm);
