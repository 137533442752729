import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  form: {
    flex: 1,
    paddingLeft: 20,
  },
  formField: {
    display: 'flex',
    alignItems: 'center',
  },
  drag: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatarContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
  },
  popup: {
    position: 'relative',
    alignSelf: 'flex-start',
    left: 0,
    bottom: 0,
  },
  black: {
    color: 'black',
  },
  flex: {
    display: 'flex',
  },
  flexGrow: {
    flex: 1,
  },
  font14: {
    fontSize: 14,
  },
});
