import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Icon, Button, Modal, Header, Form, Progress, Transition,
} from 'semantic-ui-react';
import { CSVReader } from 'react-papaparse';
import { csv } from 'csvtojson';
import _ from 'lodash';
import { getTeammates, saveTeammate } from '../../../redux/actions/team';
import Contact from '../../components/Contact';
import { parseContact } from '../../../api/helpers';

const timeLimit = 200;

const TeammateImport = ({
  show, teammates, selectedTeam, saveTeammate, setShow,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [contacts, setContacts] = useState(undefined);
  const [importing, setImporting] = useState(false);
  const [filteredCount, setFilteredCount] = useState(0);
  const [updatedCount, setUpdatedCount] = useState(0);
  const [importEnabled, setImportEnabled] = useState(false);
  const [, setError] = useState(undefined);

  useEffect(() => {
    setContacts(undefined);
    setImporting(false);
    setUpdatedCount(0);
  }, []);

  useEffect(() => {
    if (show) {
      setModalShow(show);
    } else {
      setTimeout(() => {
        setModalShow(show);
      }, timeLimit - 10);
    }
  }, [show]);

  useEffect(() => {
    if (contacts) {
      setImportEnabled(true);
    } else {
      setImportEnabled(false);
    }
  }, [contacts]);

  const handleOnDrop = async (data) => {
    const newDataString = data.map(row => row.data.join(',')).join('\n');
    csv({
      noheader: false,
      output: 'json',
    }).fromString(newDataString).then((jsonArray) => {
      const parsedContacts = jsonArray.map(contact => {
        const newContact = parseContact(0, contact);
        newContact.checked = true;
        return newContact;
      });
      const filteredContacts = parsedContacts.filter(contact => !_.isEmpty(contact.email));
      setContacts(filteredContacts);
    });
  };

  const handleOnError = (err) => {
    setError(err);
  };

  const handleOnRemoveFile = () => {
    setContacts(undefined);
    setImporting(false);
    setUpdatedCount(0);
  };

  const onFormSubmit = async () => {
    // this is where we submit all the checked contacts. Check for their emails first though.
    setImporting(true);
    const currentTeammates = teammates;
    const filteredContacts = contacts.filter(contact => contact.checked);
    setFilteredCount(filteredContacts.length);
    await filteredContacts.map(async (contact, i) => {
      await setTimeout(async () => {
        const newContact = contact;
        setUpdatedCount(i + 1);
        if (currentTeammates.find(teammate => teammate.email === newContact.email)) {
          newContact.status = 'ERROR';
          newContact.statusMessage = 'Email already exists';
        } else {
          newContact.status = 'SUCCESS';
          newContact.statusMessage = 'Imported!';
          delete newContact.checked;
          newContact.teamId = selectedTeam.id;
          newContact.approved = false;
          newContact.customerId = newContact.id;
          saveTeammate(contact);
        }
        setTimeout(() => {
          if (updatedCount >= filteredCount) {
            setImporting(false);
            setUpdatedCount(0);
          }
        }, 1000);
      }, 1000);
    });
  };

  const updateChecked = contact => {
    const updated = contacts;
    const index = updated.findIndex(c => c === contact);
    if (index > -1) {
      updated[index].checked = !updated[index].checked;
      setContacts([...updated]);
    }
  };

  return (
    <Form>
      <Transition visible={show} animation="fade" duration={{ hide: timeLimit, show: timeLimit }}>
        <Modal
          onClose={() => {
            setContacts(undefined);
            setImporting(false);
            setUpdatedCount(0);
            setShow(false);
          }}
          closeIcon
          basic
          onOpen={() => setShow(true)}
          open={modalShow}
          onSubmit={onFormSubmit}
          size="small"
        >
          <Header icon>
            <div style={{
              display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center',
            }}
            >
              <div>Import Teammates</div>
            </div>
          </Header>
          <Modal.Content style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <div style={{ width: '100%' }}>
              <CSVReader
                onDrop={handleOnDrop}
                onError={handleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={handleOnRemoveFile}
              >
                <span>Drop CSV file here or click to upload.</span>
              </CSVReader>

            </div>
            {contacts && (
            <div style={{ padding: 30, maxHeight: '50vh', overflow: 'auto' }}>

              {contacts.map(contact => (
                <div style={{
                  display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'left', alignItems: 'center', margin: 10, alignContent: 'center',
                }}
                >
                  <div style={{ paddingRight: 10 }} />
                  <div style={{ flex: 1 }}>
                    <Contact teammate={contact} updateChecked={updateChecked} />
                  </div>
                </div>
              ))}
            </div>
            )}
          </Modal.Content>
          {importing && <Progress progress="value" color="blue" indicating total={filteredCount} value={updatedCount} attached="bottom" />}
          <Modal.Actions>
            <div style={{ display: 'flex' }}>
              <div style={{ alignSelf: 'center', fontSize: 12 }}><i>After importing teammates, an email will be sent to each person for their approval to the team.</i></div>
              <div style={{ flex: 1 }}>
                <Button color="green" type="submit" onClick={onFormSubmit} disabled={!importEnabled}>
                  <Icon name="checkmark" />
                  {' '}
                  Import
                </Button>
              </div>
            </div>
          </Modal.Actions>
        </Modal>
      </Transition>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const {
    main: { customer },
    team: { teammates, selectedTeam },
  } = state;

  return { customer, teammates, selectedTeam };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  { getTeammates, saveTeammate },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(TeammateImport);
