import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Menu } from 'semantic-ui-react';
import _ from 'lodash';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import EventForm from '../../modals/event';
import { fetchEvents } from '../../../redux/actions/schedule';
import { fetchTeammates } from '../../../redux/actions/team';
import { convertFirebaseTimestampToDate } from '../../../api/helpers';
import useStyles from './styles';

const Events = ({
  schedule, customer, person, selectedTeam, fetchEvents, fetchTeammates, teammates,
}) => {
  const [showEventModal, setShowEventModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [isOwner, setIsOwner] = useState(false);

  const styles = useStyles();

  useEffect(() => {
    const dateEvents = schedule?.events?.map(event => ({
      id: event.id,
      title: event.name,
      date: convertFirebaseTimestampToDate(event.eventDate).format(),
    }));
    setEvents(dateEvents);
  }, [schedule]);

  useEffect(() => {
    if (currentEvent) {
      setShowEventModal(true);
    }
  }, [currentEvent]);

  useEffect(() => {
    if (!_.isEmpty(customer) && !_.isEmpty(person)) {
      setIsOwner(customer.email === person.email);
    }
  }, [customer, person]);

  useEffect(() => {
    if (schedule.updatedEventsDate && !_.isEmpty(selectedTeam)) {
      fetchEvents(selectedTeam?.id);
    }
  }, [schedule.updatedEventsDate, fetchEvents, selectedTeam]);

  useEffect(() => {
    if (selectedTeam && selectedTeam.id) {
      fetchEvents(selectedTeam.id);
      fetchTeammates(selectedTeam.id);
    }
  }, [selectedTeam, fetchEvents, fetchTeammates]);

  const handleDateClick = async (data) => {
    setCurrentEvent({ ...schedule.events.find(e => e.id === data.event.id) });
  };

  return (
    <div className={styles.container}>
      <Menu icon="labeled" className={styles.menu}>
        <Menu.Item
          name="addEvent"
          disabled={!isOwner || !selectedTeam || !teammates || teammates.length === 0}
          onClick={() => {
            setCurrentEvent({});
            setShowEventModal(!showEventModal);
          }}
        >
          <Icon name="calendar plus outline" />
          Add Event
        </Menu.Item>
      </Menu>
      <div>
        <FullCalendar
          aspectRatio={2.5}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          dateClick={handleDateClick}
          eventClick={handleDateClick}
          events={events}
        />
      </div>
      <EventForm setShow={setShowEventModal} show={showEventModal} event={currentEvent} />
    </div>
  );
};

const mapStateToProps = state => {
  const {
    team: { teammates, selectedTeam },
    schedule,
    main: { currentUser, customer, person },
  } = state;
  return {
    teammates, schedule, currentUser, customer, selectedTeam, person,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  { fetchEvents, fetchTeammates },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Events);
