/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Card, Item, Icon, Image, Segment, Table, Form, Button,
} from 'semantic-ui-react';
import watercoolerImage from '../../../styles/images/watercooler.png';
import watercoolerLogo from '../../../styles/images/logo.png';

const SubscriptionSelection = ({
  products, currentProductId, subscription, creditCard,
}) => {
  const [invoice, setInvoice] = useState(undefined);
  const [product, setProduct] = useState(undefined);

  useEffect(() => {
    const currentProducts = products.filter(p => p.id === currentProductId);
    if (currentProducts && currentProducts.length > 0) {
      setProduct(currentProducts[0]);
    }
  }, [products, subscription, currentProductId]);

  useEffect(() => {
    const currentInvoice = _.get(subscription, 'data.latest_invoice', undefined);
    setInvoice(currentInvoice);
  }, [subscription]);
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
      {product && (
      <Card
        name={product.id}
        style={{
          marginTop: 0,
          marginLeft: 55,
          marginRight: 5,
          marginBottom: 0,
        }}
      >
        <Card.Content textAlign="center">
          <Card.Header>{product.metadata.title}</Card.Header>
          <Card.Meta>{product.metadata.subtitle}</Card.Meta>
        </Card.Content>
        <Card.Content>
          <Item.Group>
            <Item>
              <Item.Content>
                <Item.Description>
                  <Icon name="angle right" color="green" />
                  <span dangerouslySetInnerHTML={{ __html: product.metadata.line1 }} />
                </Item.Description>
              </Item.Content>
            </Item>
            <Item>
              <Item.Content>
                <Item.Description>
                  <Icon name="angle right" color="green" />
                  <span dangerouslySetInnerHTML={{ __html: product.metadata.line2 }} />
                </Item.Description>
              </Item.Content>
            </Item>
            <Item>
              <Item.Content>
                <Item.Description>
                  <Icon name="angle right" color="green" />
                  <span dangerouslySetInnerHTML={{ __html: product.metadata.line3 }} />
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </Card.Content>
      </Card>
      )}
      <Form style={{ flex: 1, marginRight: 55, marginLeft: 20 }}>
        <Segment placeholder style={{ margin: 0, width: '100%', alignItems: 'center' }}>
          {invoice && (
          <Table celled={false}>
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={2} textAlign="center">
                  <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                    <Image src={watercoolerImage} style={{ height: 40 }} />
                    <Image src={watercoolerLogo} style={{ height: 40 }} />
                  </div>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="left">Name</Table.Cell>
                <Table.Cell textAlign="right">{invoice.customer_name}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="left">Invoice Number</Table.Cell>
                <Table.Cell textAlign="right">{invoice.number}</Table.Cell>
              </Table.Row>
              {creditCard && creditCard.card && (
              <Table.Row>
                <Table.Cell textAlign="left">Payment Method</Table.Cell>
                <Table.Cell textAlign="right">
                  {_.capitalize(creditCard.card.brand)}
                  {' '}
                  ••••
                  {' '}
                  {creditCard.card.last4}
                </Table.Cell>
              </Table.Row>
              )}
              <Table.Row>
                <Table.Cell colSpan={2} textAlign="center">
                  <Button primary onClick={() => window.open(invoice.invoice_pdf, '_blank')}>Download Invoice</Button>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          )}
        </Segment>
      </Form>
    </div>
  );
};

export default SubscriptionSelection;
