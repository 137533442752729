import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Icon, Button, Modal, Header, Form,
} from 'semantic-ui-react';
import _ from 'lodash';
import { approveTeam } from '../../../redux/actions/team';
import TeamApproval from '../../components/TeamApproval';

const TeammateApproval = ({
  approveTeam, currentUser, show, setShow, unapprovedTeams,
}) => {
  const handleApproval = (teamId, approve) => {
    approveTeam(currentUser.email, teamId, approve);
  };

  return (
    <Form>
      <Modal
        onClose={() => setShow(false)}
        onOpen={() => setShow(true)}
        open={show}
        size="small"
        basic
      >
        <Header icon>
          <div style={{
            display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center',
          }}
          >
            {!_.isEmpty(unapprovedTeams) && (
              <div>Teams have added you! You can approve or deny....</div>
            )}
            {_.isEmpty(unapprovedTeams) && (<div>All set! Thanks!</div>)}
          </div>
        </Header>
        <Modal.Content style={{ display: 'flex', flex: 1 }}>
          <>
            {unapprovedTeams && unapprovedTeams.map(team => (
              <TeamApproval team={team} approveFunction={handleApproval} />
            ))}
          </>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="green" onClick={() => setShow(false)}>
            <Icon name="checkmark" />
            {!_.isEmpty(unapprovedTeams) ? 'Do it later' : 'Ok'}
          </Button>
        </Modal.Actions>
      </Modal>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const {
    main: { currentUser },
    team: { unapprovedTeams },
  } = state;

  return { currentUser, unapprovedTeams };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  { approveTeam },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(TeammateApproval);
