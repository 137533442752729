import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Icon, Button, Modal, Header, Form, Image, Message,
} from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import _ from 'lodash';
import {
  loginUser, fetchCustomer, sendNewVerificationEmail, fetchPerson,
} from '../../../redux/actions/main';
import watercoolerImage from '../../../styles/images/logo.png';

const LoginForm = ({
  currentUserError, currentUser, fetchCustomer, fetchPerson, customer, person, loginUser,
}) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [pushEmailAddress, setPushEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');

  const history = useHistory();

  useEffect(() => {
    if (currentUserError) {
      let displayErrorMessage = '';
      setLoggingIn(false);
      switch (currentUserError.code) {
        case 'auth/wrong-password':
          displayErrorMessage = 'The password entered is incorrect';
          break;
        case 'auth/user-not-found':
          displayErrorMessage = 'No account found for this email address';
          break;
        default:
          displayErrorMessage = 'Unable to login at this time';
      }
      toast({ title: 'Error', description: <p>{displayErrorMessage}</p> });
    }

    if (currentUser) {
      fetchCustomer(currentUser.email);
      fetchPerson(currentUser.email);
    }
  }, [currentUser, currentUserError, fetchCustomer, fetchPerson]);

  useEffect(() => {
    if (!_.isEmpty(customer)) {
      if (customer.validated) {
        history.push('/dashboard');
      } else {
        setErrorMessage('Email validation must be completed before proceeding. Would you like us to send another email verification?');
        setPushEmailAddress(emailAddress);
        setLoggingIn(false);
      }
    }
  }, [history, emailAddress, customer]);

  useEffect(() => {
    if (_.isEmpty(customer) && !_.isEmpty(person)) {
      // not a customer but a teammate
      history.push('/dashboard');
    }
  }, [history, customer, person]);

  const sendNewMessage = () => {
    setErrorMessage('');
    sendNewVerificationEmail(pushEmailAddress);
    setMessage('A new verification email has been sent. Please check your email.');
  };

  const login = () => {
    setLoggingIn(true);
    loginUser({ email: emailAddress, password });
  };

  return (
    <Form>
      <Modal open basic size="mini">
        <Header icon>
          <div style={{
            display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', color: 'white',
          }}
          >
            <div>
              <Image src={watercoolerImage} size="small" wrapped />
            </div>
          </div>
        </Header>

        {errorMessage && (
          <Message
            warning
            content={(
              <div>
                <div>{errorMessage}</div>
                <Button onClick={sendNewMessage}>Resend Verification email</Button>
              </div>
            )}
          />
        )}
        {message && <Message floating positive content={message} />}
        {!message && (
          <Modal.Content style={{ display: 'flex', flex: 1 }}>
            <>
              <Form style={{ width: 500 }} autoComplete="off" widths="equal" inverted onSubmit={login}>
                <SemanticToastContainer />
                <Form.Group>
                  <Form.Input
                    width={16}
                    inverted
                    label="Email"
                    name="email"
                    autoComplete="off"
                    value={emailAddress}
                    onChange={(e, d) => setEmailAddress(d.value)}
                    placeholder="Enter your email..."
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Input
                    width={16}
                    label="Password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    inverted
                    fluid
                    value={password}
                    onChange={(e, d) => setPassword(d.value)}
                    placeholder="Enter your password..."
                  />
                </Form.Group>
                <Form.Group>
                  <Button color="green" loading={loggingIn} disabled={loggingIn} fluid onClick={login}>
                    <Icon name="checkmark" />
                    Login
                  </Button>
                </Form.Group>
                <Form.Group>
                  <div style={{ display: 'flex', flex: 0.5, justifyContent: 'space-between' }}>
                    <a href="/forgotpassword">Forgot password?</a>
                  </div>
                  <div style={{
                    display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'flex-end',
                  }}
                  >
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                      Don&apos;t have an account?&nbsp;&nbsp;
                      <a href="/signup">Sign up!</a>
                    </div>
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                      Invited to a team?&nbsp;&nbsp;
                      <a href="/join">Join!</a>
                    </div>

                  </div>
                </Form.Group>
              </Form>
            </>
          </Modal.Content>
        )}
      </Modal>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const {
    main: {
      customer, currentUser, currentUserError, person,
    },
  } = state;

  return {
    customer, currentUser, currentUserError, person,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    loginUser, fetchPerson, fetchCustomer, sendNewVerificationEmail,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
