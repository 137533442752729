/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useCallback } from 'react';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import {
  Form, Card, Item, Icon, Label, Message,
} from 'semantic-ui-react';

const SubscriptionPaymentForm = ({
  setNextDisabled, products, subscription, paymentError,
}) => {
  const [cardNumber, setCardNumber] = useState(undefined);
  const [cardExpiration, setCardExpiration] = useState(undefined);
  const [cardCvc, setCardCvc] = useState(undefined);
  const [product, setProduct] = useState(undefined);

  const isComplete = useCallback(() => cardNumber
    && cardCvc
    && cardExpiration
    && cardNumber.complete
    && cardCvc.complete
    && cardCvc.complete,
  [cardNumber, cardCvc, cardExpiration]);

  useEffect(() => {
    setNextDisabled(!isComplete());
  }, [cardNumber, cardExpiration, cardCvc, isComplete, setNextDisabled]);

  useEffect(() => {
    const currentProducts = products.filter(p => p.id === subscription);
    if (currentProducts && currentProducts.length > 0) {
      setProduct(currentProducts[0]);
    }
  }, [products, subscription]);

  const getCCIcon = () => {
    if (!cardNumber || !cardNumber.brand || cardNumber.brand === 'unknown' || cardNumber.brand === 'unionpay') {
      return 'credit card';
    }

    if (cardNumber.brand === 'diners') {
      return 'cc diners club';
    }

    return `cc ${cardNumber.brand}`;
  };

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
      {product && (
      <Card
        name={product.id}
        style={{
          marginTop: 0,
          marginLeft: 55,
          marginRight: 5,
          marginBottom: 0,
        }}
      >
        <Card.Content textAlign="center">
          <Card.Header>{product.metadata.title}</Card.Header>
          <Card.Meta>{product.metadata.subtitle}</Card.Meta>
        </Card.Content>
        <Card.Content>
          <Item.Group>
            <Item>
              <Item.Content>
                <Item.Description>
                  <Icon name="angle right" color="green" />
                  <span dangerouslySetInnerHTML={{ __html: product.metadata.line1 }} />
                </Item.Description>
              </Item.Content>
            </Item>
            <Item>
              <Item.Content>
                <Item.Description>
                  <Icon name="angle right" color="green" />
                  <span dangerouslySetInnerHTML={{ __html: product.metadata.line2 }} />
                </Item.Description>
              </Item.Content>
            </Item>
            <Item>
              <Item.Content>
                <Item.Description>
                  <Icon name="angle right" color="green" />
                  <span dangerouslySetInnerHTML={{ __html: product.metadata.line3 }} />
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </Card.Content>
      </Card>
      )}
      <Form style={{ flex: 1, marginRight: 55, marginLeft: 20 }}>
        <Form.Field>
          {cardNumber && cardNumber.error && (<Label basic color="red" style={{ position: 'absolute', top: -15, left: '25%' }} pointing="below">{cardNumber.error.message}</Label>)}
          <label>
            Card Number
            {' '}
            <Icon name={getCCIcon()} />
          </label>
          <CardNumberElement onChange={setCardNumber} className="aaron" />
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Expiration</label>
            <CardExpiryElement onChange={setCardExpiration} className="aaron" />
            {cardExpiration && cardExpiration.error && (<Label basic color="red" style={{ position: 'relative' }} pointing>{cardExpiration.error.message}</Label>)}
          </Form.Field>
          <Form.Field>
            <label>CVC</label>
            <CardCvcElement onChange={setCardCvc} className="aaron" />
            {cardCvc && cardCvc.error && (<Label basic color="red" style={{ position: 'relative' }} pointing>{cardCvc.error.message}</Label>)}
          </Form.Field>
        </Form.Group>
        <Message
          visible={paymentError !== undefined}
          error
          header="There was an error processing your payment"
          content={paymentError}
        />
      </Form>
    </div>
  );
};

export default SubscriptionPaymentForm;
