import { createUseStyles } from 'react-jss';

const styles = (background) => ({
  item: {
    backgroundColor: background,
    padding: 10,
  },
  message: {
    flex: 1,
    textAlign: 'right',
    alignSelf: 'center',
    padding: 10,
  },
});

export default createUseStyles(styles);
