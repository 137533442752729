import {
  createStore, applyMiddleware, combineReducers, compose,
} from 'redux';
import thunk from 'redux-thunk';
import firebase from 'firebase';

import initial from './initialState';
import team from './modules/team';
import schedule from './modules/schedule';
import main from './modules/main';
import settings from './modules/settings';

const isEnvProduction = false;

let composeEnhancers = compose;
// For local development, log to Redux devtools excluding certain actions
if (isEnvProduction && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  console.log(`✨✨✨✨ Redux devtools enabled ✨✨✨✨`); // eslint-disable-line
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    actionsBlacklist: ['@@router/LOCATION_CHANGE'],
  });
}

const firebaseConfig = {
  apiKey: 'AIzaSyCZi813L09CpOa1tLPyXAX90yugWc4qngU',
  authDomain: 'water-cooler-cfcac.firebaseapp.com',
  databaseURL: 'https://water-cooler-cfcac-default-rtdb.firebaseio.com/',
  projectId: 'water-cooler-cfcac',
  storageBucket: 'water-cooler-cfcac.appspot.com',
  appId: '1:325463666267:web:b31fa1f0da032df047597e',
};

firebase.initializeApp(firebaseConfig);

if (!isEnvProduction) {
  // firebase.functions().useEmulator('localhost', 5001);
  // firebase.auth().useEmulator('http://localhost:9099/');
  // firebase.database().useEmulator('localhost', 9001);
}

const configureStore = (initialState = initial) => createStore(
  combineReducers({
    team,
    schedule,
    main,
    settings,
  }),
  initialState,
  composeEnhancers(applyMiddleware(thunk)),
);

export default configureStore;
