import React from 'react';
import { Icon } from 'semantic-ui-react';

const TourTeam = () => (
  <div style={{
    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1, height: '100%', padding: 20,
  }}
  >
    <Icon name="group" size="massive" />
    <div style={{ fontSize: 16 }}>
      First thing, let&apos;s create a team!
    </div>
  </div>
);

export default TourTeam;
