import React, { useEffect, useState } from 'react';
import {
  Form, Input, Select, Button, Checkbox, TextArea,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TimeInput } from 'semantic-ui-calendar-react';
import _ from 'lodash';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import { fetchSettings, saveSettings } from '../../../../redux/actions/settings';
import { getAllTimezones } from '../../../../api/helpers';
import 'react-semantic-toasts/styles/react-semantic-alert.css';

const EventSettings = ({
  settings, customer, saveSettings, fetchSettings,
}) => {
  const [occurance, setOccurance] = useState('daily');
  const [startTime, setStartTime] = useState('12:01 AM');
  const [endTime, setEndTime] = useState('11:59 PM');
  const [sunday, setSunday] = useState(true);
  const [monday, setMonday] = useState(true);
  const [tuesday, setTuesday] = useState(true);
  const [wednesday, setWednesday] = useState(true);
  const [thursday, setThursday] = useState(true);
  const [friday, setFriday] = useState(true);
  const [saturday, setSaturday] = useState(true);
  const [allTimezones, setAllTimezones] = useState([]);
  const [timezone, setTimezone] = useState('America/New_York');
  const [zoomPassword, setZoomPassword] = useState('');
  const [autoScheduleEventText, setAutoScheduleEventText] = useState('');

  const buildOptions = (options) => options.map((option) => ({ key: option, text: option.replace('_', ' '), value: option }));

  useEffect(() => {
    if (!_.isEmpty(settings)) {
      setOccurance(settings?.occurance);
      setStartTime(settings?.startTime);
      setEndTime(settings?.endTime);
      setSunday(settings?.sunday);
      setMonday(settings?.monday);
      setTuesday(settings?.tuesday);
      setWednesday(settings?.wednesday);
      setThursday(settings?.thursday);
      setFriday(settings?.friday);
      setSaturday(settings?.saturday);
      setTimezone(settings?.timezone);
      setZoomPassword(settings?.zoomPassword || '');
      setAutoScheduleEventText(settings?.autoScheduleEventText);
    } else if (!_.isEmpty(customer)) {
      fetchSettings(customer.id);
    }
    setAllTimezones(buildOptions(getAllTimezones()));
  }, [settings, customer, fetchSettings]);

  const occuranceOptions = [
    { key: 'd', text: 'Daily', value: 'daily' },
    { key: 'w', text: 'Weekly', value: 'weekly' },
    { key: 'b', text: 'Bi-weekly', value: 'biweekly' },
    { key: 'm', text: 'Monthly', value: 'monthly' },
  ];

  const handleSave = () => {
    if (!_.isEmpty(customer)) {
      if (customer.id) {
        const data = {
          customerId: customer.id,
          occurance,
          startTime,
          endTime,
          sunday,
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          timezone,
          zoomPassword,
          autoScheduleEventText,
        };
        if (settings && settings.id) {
          data.id = settings.id;
        }

        saveSettings(data);
        setTimeout(() => toast({ title: 'Saved', description: <p>Settings have been saved!</p> }), 500);
      }
    }
  };

  return (
    <Form>
      <SemanticToastContainer />
      <Form.Group widths="equal">
        <Form.Select
          control={Select}
          options={occuranceOptions}
          label={{ children: 'Occurance', htmlFor: 'form-select-control-occurance' }}
          placeholder="How often..."
          onChange={(e, data) => setOccurance(data.value)}
          value={occurance}
          search
          searchInput={{ id: 'form-select-control-occurance' }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field
          control={Checkbox}
          label="Sunday"
          checked={sunday}
          onChange={(e, data) => setSunday(data.checked)}
        />
        <Form.Field
          control={Checkbox}
          label="Monday"
          checked={monday}
          onChange={(e, data) => setMonday(data.checked)}
        />
        <Form.Field
          control={Checkbox}
          label="Tuesday"
          checked={tuesday}
          onChange={(e, data) => setTuesday(data.checked)}
        />
        <Form.Field
          control={Checkbox}
          label="Wednesday"
          checked={wednesday}
          onChange={(e, data) => setWednesday(data.checked)}
        />
        <Form.Field
          control={Checkbox}
          label="Thursday"
          checked={thursday}
          onChange={(e, data) => setThursday(data.checked)}
        />
        <Form.Field
          control={Checkbox}
          label="Friday"
          checked={friday}
          onChange={(e, data) => setFriday(data.checked)}
        />
        <Form.Field
          control={Checkbox}
          label="Saturday"
          checked={saturday}
          onChange={(e, data) => setSaturday(data.checked)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field
          id="form-input-control-earliest"
          label="Earliest"
          control={TimeInput}
          placeholder="What is the earliest the meeting should start..."
          value={startTime}
          timeFormat="AMPM"
          iconPosition="left"
          onChange={(e, data) => setStartTime(data.value)}
        />
        <Form.Field
          id="fform-input-control-latest"
          label="Latest"
          control={TimeInput}
          placeholder="What is the latest the meeting should start..."
          value={endTime}
          timeFormat="AMPM"
          iconPosition="left"
          onChange={(e, data) => setEndTime(data.value)}
        />
        <Form.Select
          control={Select}
          options={allTimezones}
          label={{ children: 'Timezone', htmlFor: 'form-select-control-timezone' }}
          placeholder="What is your timezone..."
          onChange={(e, data) => setTimezone(data.value)}
          value={timezone}
          search
          searchInput={{ id: 'form-select-control-timezone' }}
        />
      </Form.Group>
      <Form.Group widths={2}>
        <Form.Field
          id="form-input-control-zoom-password"
          control={Input}
          value={zoomPassword}
          onChange={(e, data) => setZoomPassword(data.value)}
          label="Zoom Meeting Password"
          placeholder="What would you like the meeting password to be..."
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          id="form-input-control-auto-schedule-event-text"
          control={TextArea}
          rows={10}
          value={autoScheduleEventText}
          onChange={(e, data) => setAutoScheduleEventText(data.value)}
          label="Auto Scheduled Event Email Text"
          placeholder="What would you like the email to say when events are auto scheduled..."
        />
      </Form.Group>
      <Form.Field
        id="form-button-control-public"
        control={Button}
        content="Save"
        onClick={handleSave}
      />
    </Form>
  );
};

const mapStateToProps = (state) => {
  const {
    settings: { settings },
    main: { customer },
  } = state;

  return {
    settings, customer,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    fetchSettings, saveSettings,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(EventSettings);
