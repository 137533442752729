import firebase from 'firebase';
import moment from 'moment';

import {
  DRAWER_OPEN,
  DRAWER_CLOSE,
  GET_CUSTOMER,
  GET_PERSON,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_LOGIN_FAILED,
  RESET_CURRENT_USER,
  START_LOADING,
  FINISH_LOADING,
  FINISH_TOUR,
  FORGOT_PASSWORD_SUCCESS,
  FIND_MY_CUSTOMERS,
  VALIDATE_CUSTOMER,
  NEW_VALIDATION_EMAIL,
} from '../actionTypes';

export function startLoading() {
  return { type: START_LOADING };
}

export function finishLoading() {
  return { type: FINISH_LOADING };
}

export function finishTour() {
  return { type: FINISH_TOUR };
}

export function openDrawer() {
  return { type: DRAWER_OPEN };
}

export function closeDrawer() {
  return { type: DRAWER_CLOSE };
}

export function resetCurrentUser() {
  return { type: RESET_CURRENT_USER };
}

export function getCustomer(data) {
  return { type: GET_CUSTOMER, payload: data };
}

export function updateValidateCustomer(data) {
  return { type: VALIDATE_CUSTOMER, payload: data };
}

export function getMyCustomers(data) {
  return { type: FIND_MY_CUSTOMERS, payload: data };
}

export function getPerson(data) {
  return { type: GET_PERSON, payload: data };
}

export function setUserLoginSuccess(data) {
  return { type: USER_LOGIN_SUCCESS, payload: data };
}

export function setForgotPasswordSuccess() {
  return { type: FORGOT_PASSWORD_SUCCESS };
}

export function setUserLogoutSuccess() {
  return { type: USER_LOGOUT_SUCCESS };
}

export function setUserLoginFailed(error) {
  return { type: USER_LOGIN_FAILED, payload: error };
}

export function newValidationEmail(validation) {
  return { type: NEW_VALIDATION_EMAIL, payload: validation };
}

const saveNewPersonDirect = (data) => {
  const callable = firebase.functions().httpsCallable('saveNewPerson');
  return callable(data);
};

export function fetchPerson(email) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('getPerson');
    callable(email).then(data => {
      dispatch(getPerson(data.data));
    });
  };
}

export function fetchCustomer(email) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('getCustomer');
    callable(email).then(data => {
      dispatch(getCustomer(data.data));
    });
  };
}

export function logoutUser() {
  return dispatch => {
    dispatch(resetCurrentUser());
    firebase.auth().signOut().then(() => {
      fetchPerson(null);
      fetchCustomer(null);
    });
  };
}

const saveCustomerDirect = (data) => {
  const callable = firebase.functions().httpsCallable('saveCustomer');
  return callable(data);
};

const sendVerificationEmail = (data) => {
  const callable = firebase.functions().httpsCallable('sendCustomerVerificationEmail');
  return callable(data);
};

export function loginUser(user) {
  return dispatch => {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        firebase
          .auth()
          .signInWithEmailAndPassword(user.email, user.password)
          .then((data) => {
            dispatch(setUserLoginSuccess(data.user));
          }).catch(e => dispatch(setUserLoginFailed(e)));
      });
  };
}

export function forgotPassword(email) {
  return dispatch => {
    firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        dispatch(setForgotPasswordSuccess());
      }).catch(e => dispatch(setUserLoginFailed(e)));
  };
}

export function fetchCustomerById(id) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('getCustomerById');
    callable(id).then(data => {
      dispatch(getCustomer(data.data));
    });
  };
}

export function findMyCustomers(email) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('findMyCustomers');
    callable(email).then(data => {
      dispatch(getMyCustomers(data.data));
    });
  };
}

export function saveCustomerAvatar(avatar, customer) {
  return dispatch => {
    if (avatar) {
      firebase.storage().ref('/avatars/customer').child(customer.id)
        .putString(avatar, 'data_url')
        .then(() => {
          firebase.storage().ref(`/avatars/customer/${customer.id}`).getDownloadURL().then(url => {
            const customerData = { ...customer, avatar: url };
            saveCustomerDirect(customerData).then(customerInfo => {
              dispatch(fetchCustomerById(customerInfo.data.id));
              dispatch(findMyCustomers(customerInfo.data.email));
            });
          });
        });
    } else {
      const customerData = { ...customer, avatar: '' };
      saveCustomerDirect(customerData).then(customerInfo => {
        dispatch(fetchCustomerById(customerInfo.data.id));
        dispatch(findMyCustomers(customerInfo.data.email));
      });
    }
  };
}

const savePersonDirect = (data) => {
  const callable = firebase.functions().httpsCallable('savePerson');
  return callable(data);
};

export function saveAvatar(avatar, person) {
  return dispatch => {
    if (avatar) {
      firebase.storage().ref('/avatars/person').child(person.id)
        .putString(avatar, 'data_url')
        .then(() => {
          firebase.storage().ref(`/avatars/person/${person.id}`).getDownloadURL().then(url => {
            const personData = { ...person, avatar: url };
            savePersonDirect(personData).then(() => {
              dispatch(fetchPerson(person.email));
            });
          });
        });
    } else {
      const personData = { ...person, avatar: '' };
      savePersonDirect(personData).then(() => {
        dispatch(fetchPerson(person.email));
      });
    }
  };
}

export function signupUser(user) {
  return dispatch => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password)
      .then((data) => {
        const newCustomer = {
          companyName: user.companyName,
          email: user.email,
          name: user.name,
          subscriptionType: 0,
          createdDate: moment().toDate(),
          validated: false,
        };
        const newPerson = { email: user.email, name: user.name, createdDate: moment().toDate() };
        saveNewPersonDirect(newPerson).then(userInfo => {
          fetchPerson(userInfo.email);
        });
        saveCustomerDirect(newCustomer).then(userInfo => {
          fetchCustomer(userInfo.data.email);
          sendVerificationEmail(userInfo.data.email);
          dispatch(setUserLoginSuccess(data.user));
        });
      }).catch(e => dispatch(setUserLoginFailed(e)));
  };
}

export function signupTeammate(user) {
  return dispatch => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password)
      .then((data) => {
        const newPerson = { email: user.email, name: user.name, createdDate: moment().toDate() };
        saveNewPersonDirect(newPerson).then(userInfo => {
          fetchPerson(userInfo.email);
          dispatch(setUserLoginSuccess(data.user));
        });
      }).catch(e => dispatch(setUserLoginFailed(e)));
  };
}

export function fetchPersonById(id) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('getPersonById');
    callable(id).then(data => {
      dispatch(getPerson(data.data));
    });
  };
}

export function sendNewVerificationEmail(email) {
  return dispatch => {
    sendVerificationEmail(email).then(validate => {
      dispatch(newValidationEmail(validate));
    });
  };
}

export function validateCustomer(data) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('validateCustomer');
    callable(data).then(validate => {
      dispatch(updateValidateCustomer(validate.data));
    });
  };
}

export function saveCustomer(data, avatar) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('saveCustomer');
    callable(data).then(customer => {
      dispatch(saveCustomerAvatar(avatar, customer.data));
    });
  };
}

export function savePerson(data, avatar) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('savePerson');
    callable(data).then(data => {
      dispatch(saveAvatar(avatar, data.data));
    });
  };
}
