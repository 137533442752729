import React from 'react';
import { Grid, Item, Checkbox } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import useStyles from './styles';

const Contact = ({
  teammate, updateChecked,
}) => {
  let backgroundColor = 'aliceblue';
  if (teammate.status === 'ERROR') {
    backgroundColor = '#fff0f8';
  } else if (teammate.status === 'SUCCESS') {
    backgroundColor = '#f0fff7';
  }

  const styles = useStyles(backgroundColor);

  return (
    <Item.Group>
      <Item classname={styles.item}>
        <Checkbox checked={teammate.checked} value={teammate} onChange={() => updateChecked(teammate)} className={{ alignSelf: 'center' }} />
        <div className={{ width: 10 }} />
        <Avatar size={50} round src={teammate.avatar} name={teammate.name} />
        <div className={{ paddingLeft: 5 }}>
          <Item.Header>{teammate.name}</Item.Header>
          <Item.Description>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  {teammate.email}
                </Grid.Column>
                <Grid.Column>
                  {teammate.description}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Item.Description>
        </div>
        <div className={styles.message}>
          <i>{teammate.statusMessage}</i>
        </div>
      </Item>
    </Item.Group>
  );
};
export default Contact;
