import bullseyeAchieved from '../../../styles/images/awards/achieved/bullseye.png';
import chatbubbleAchieved from '../../../styles/images/awards/achieved/chatbubble.png';
import checkAchieved from '../../../styles/images/awards/achieved/check.png';
import clockAchieved from '../../../styles/images/awards/achieved/clock.png';
import crownAchieved from '../../../styles/images/awards/achieved/crown.png';
import fireAchieved from '../../../styles/images/awards/achieved/fire.png';
import flagAchieved from '../../../styles/images/awards/achieved/flag.png';
import gemAchieved from '../../../styles/images/awards/achieved/gem.png';
import globeAchieved from '../../../styles/images/awards/achieved/globe.png';
import heartAchieved from '../../../styles/images/awards/achieved/heart.png';
import hourglassAchieved from '../../../styles/images/awards/achieved/hourglass.png';
import keyAchieved from '../../../styles/images/awards/achieved/key.png';
import lightbulbAchieved from '../../../styles/images/awards/achieved/lightbulb.png';
import lockAchieved from '../../../styles/images/awards/achieved/lock.png';
import musicnotesAchieved from '../../../styles/images/awards/achieved/musicnotes.png';
import pencilAchieved from '../../../styles/images/awards/achieved/pencil.png';
import questionAchieved from '../../../styles/images/awards/achieved/question.png';
import starAchieved from '../../../styles/images/awards/achieved/star.png';
import suitcaseAchieved from '../../../styles/images/awards/achieved/suitcase.png';
import trophyAchieved from '../../../styles/images/awards/achieved/trophy.png';

import bullseyeUnachieved from '../../../styles/images/awards/unachieved/bullseye.png';
import chatbubbleUnachieved from '../../../styles/images/awards/unachieved/chatbubble.png';
import checkUnachieved from '../../../styles/images/awards/unachieved/check.png';
import clockUnachieved from '../../../styles/images/awards/unachieved/clock.png';
import crownUnachieved from '../../../styles/images/awards/unachieved/crown.png';
import fireUnachieved from '../../../styles/images/awards/unachieved/fire.png';
import flagUnachieved from '../../../styles/images/awards/unachieved/flag.png';
import gemUnachieved from '../../../styles/images/awards/unachieved/gem.png';
import globeUnachieved from '../../../styles/images/awards/unachieved/globe.png';
import heartUnachieved from '../../../styles/images/awards/unachieved/heart.png';
import hourglassUnachieved from '../../../styles/images/awards/unachieved/hourglass.png';
import keyUnachieved from '../../../styles/images/awards/unachieved/key.png';
import lightbulbUnachieved from '../../../styles/images/awards/unachieved/lightbulb.png';
import lockUnachieved from '../../../styles/images/awards/unachieved/lock.png';
import musicnotesUnachieved from '../../../styles/images/awards/unachieved/musicnotes.png';
import pencilUnachieved from '../../../styles/images/awards/unachieved/pencil.png';
import questionUnachieved from '../../../styles/images/awards/unachieved/question.png';
import starUnachieved from '../../../styles/images/awards/unachieved/star.png';
import suitcaseUnachieved from '../../../styles/images/awards/unachieved/suitcase.png';
import trophyUnachieved from '../../../styles/images/awards/unachieved/trophy.png';

const achieved = {
  bullseye: bullseyeAchieved,
  chatbubble: chatbubbleAchieved,
  check: checkAchieved,
  clock: clockAchieved,
  crown: crownAchieved,
  fire: fireAchieved,
  flag: flagAchieved,
  gem: gemAchieved,
  globe: globeAchieved,
  heart: heartAchieved,
  hourglass: hourglassAchieved,
  key: keyAchieved,
  lightbulb: lightbulbAchieved,
  lock: lockAchieved,
  musicnotes: musicnotesAchieved,
  pencil: pencilAchieved,
  question: questionAchieved,
  star: starAchieved,
  suitcase: suitcaseAchieved,
  trophy: trophyAchieved,
};

const unachieved = {
  bullseye: bullseyeUnachieved,
  chatbubble: chatbubbleUnachieved,
  check: checkUnachieved,
  clock: clockUnachieved,
  crown: crownUnachieved,
  fire: fireUnachieved,
  flag: flagUnachieved,
  gem: gemUnachieved,
  globe: globeUnachieved,
  heart: heartUnachieved,
  hourglass: hourglassUnachieved,
  key: keyUnachieved,
  lightbulb: lightbulbUnachieved,
  lock: lockUnachieved,
  musicnotes: musicnotesUnachieved,
  pencil: pencilUnachieved,
  question: questionUnachieved,
  star: starUnachieved,
  suitcase: suitcaseUnachieved,
  trophy: trophyUnachieved,
};

export default {
  achieved, unachieved,
};

const awardData = [
  {
    name: 'bullseye', header: 'Bullseye Achievement', text: "You've nailed a topic with the best answer!", unachievedText: 'When your answer is voted as the best, you win this achievement.',
  },
  {
    name: 'chatbubble', header: 'Chat Achievement', text: 'You enjoy talking', unachievedText: 'Talk more and unlock this achievement.',
  },
  {
    name: 'check', header: 'Attendance Achievement', text: "Bueller? Bueller? Oh wait, you're here!", unachievedText: 'Join a WaterCooler event and mark this achievement achieved!',
  },
  {
    name: 'clock', header: 'Patience Achievement', text: 'You made it through an event, start to finish', unachievedText: 'Gotta be at an event the whole time to get this one.',
  },
  {
    name: 'crown', header: 'King of the Hill Achievement', text: 'Boom, you were on top!', unachievedText: 'Get first place on the leaderboard to win this achievement.',
  },
  {
    name: 'fire', header: 'Top Comment Achievement', text: 'Ok, that was fire.', unachievedText: 'Get 10 people to like a comment, and this is yours.',
  },
  {
    name: 'flag', header: 'Generic Achievement', text: 'Generic text', unachievedText: 'Generic text unachieved',
  },
  {
    name: 'gem', header: 'Generic Achievement', text: 'Generic text', unachievedText: 'Generic text unachieved',
  },
  {
    name: 'globe', header: 'Generic Achievement', text: 'Generic text', unachievedText: 'Generic text unachieved',
  },
  {
    name: 'heart', header: 'Generic Achievement', text: 'Generic text', unachievedText: 'Generic text unachieved',
  },
  {
    name: 'hourglass', header: 'Generic Achievement', text: 'Generic text', unachievedText: 'Generic text unachieved',
  },
  {
    name: 'key', header: 'Generic Achievement', text: 'Generic text', unachievedText: 'Generic text unachieved',
  },
  {
    name: 'lightbulb', header: 'Generic Achievement', text: 'Generic text', unachievedText: 'Generic text unachieved',
  },
  {
    name: 'lock', header: 'Generic Achievement', text: 'Generic text', unachievedText: 'Generic text unachieved',
  },
  {
    name: 'musicnotes', header: 'Generic Achievement', text: 'Generic text', unachievedText: 'Generic text unachieved',
  },
  {
    name: 'pencil', header: 'Account Achievement', text: 'You created an account, joined a team!', unachievedText: 'Generic text unachieved',
  },
  {
    name: 'question', header: 'Question Suggestion Achievement', text: "That's a good question! Great job!", unachievedText: 'Suggest a question and this achievement is in the bag.',
  },
  {
    name: 'star', header: 'Generic Achievement', text: 'Generic text', unachievedText: 'Generic text unachieved',
  },
  {
    name: 'suitcase', header: 'Generic Achievement', text: 'Generic text', unachievedText: 'Generic text unachieved',
  },
  {
    name: 'trophy', header: 'Generic Achievement', text: 'Generic text', unachievedText: 'Generic text unachieved',
  },
];

export { awardData };
