import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  Icon, Button, Menu, Popup, Header, Segment, Form,
} from 'semantic-ui-react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import Avatar from 'react-avatar';
import { useHistory } from 'react-router-dom';
import { fetchTeammatesDirect, setSelectedTeam } from '../../../redux/actions/team';
import Profile from '../../components/Profile';
import useStyles from './styles';
import TeammateForm from '../../modals/teammate';
import TeamForm from '../../modals/team';
import TeammateImportForm from '../../modals/teammateImport';
import Person from '../../components/Person';
import DeleteTeam from '../../modals/deleteTeam';

const Teams = ({
  teams, selectedTeam,
  setSelectedTeam,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [activeTeam, setActiveTeam] = useState(undefined);
  const [activeTeamObject, setActiveTeamObject] = useState(undefined);
  const [teammates, setTeammates] = useState([]);
  const [activeTeammate, setActiveTeammate] = useState(undefined);
  const [showAddTeamModal, setShowAddTeamModal] = useState(false);
  const [showAddTeammateModal, setShowAddTeammateModal] = useState(false);
  const [showTeammateImportModal, setShowTeammateImportModal] = useState(false);
  const [newTeammate, setNewTeammate] = useState(false);
  const [newTeam, setNewTeam] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleTeamClick = async (e, { name }) => setActiveTeam(name);
  const handleTeammateClick = async (e, { name }) => setActiveTeammate(name);

  const getActiveTeam = useCallback(() => {
    if (!teams || !activeTeam) {
      return undefined;
    }
    return teams.find(t => t.id === activeTeam);
  }, [teams, activeTeam]);

  const getActiveTeammate = useCallback(() => {
    if (!teammates || !activeTeammate) {
      return undefined;
    }
    return teammates.find(t => t.id === activeTeammate);
  }, [teammates, activeTeammate]);

  useEffect(() => {
    if (activeTeam) {
      setActiveTeamObject(getActiveTeam());
      setActiveTeammate(undefined);
      setTeammates([]);
      fetchTeammatesDirect(activeTeam).then(data => setTeammates(data.data));
    }
  }, [activeTeam, getActiveTeam]);

  useEffect(() => {
    if (teams && teams.length > 0) {
      if (activeTeam === undefined) {
        setActiveTeam(teams[0].id);
      }

      if (selectedTeam === undefined) {
        setSelectedTeam(teams[0].id);
      }
    }
  }, [teams, activeTeam, selectedTeam, setSelectedTeam]);

  useEffect(() => {
    if (teammates && teammates.length > 0) {
      if (activeTeammate === undefined) {
        setActiveTeammate(teammates[0].id);
      }
    }
  }, [teammates, activeTeammate]);

  useEffect(() => {
    setActiveTeamObject(getActiveTeam());
  }, [teams, getActiveTeam]);

  if (!teams || teams.length === 0) {
    return (
      <TeamForm
        setShow={setShowAddTeamModal}
        title="Please create your first team!"
        show
        onCancel={() => history.goBack()}
      />
    );
  }

  return (
    <div className={classes.container} id="teams">
      <div className={classes.teamMenuDiv}>
        <Menu vertical icon className={classes.menu}>
          {!_.isEmpty(teams) && teams.map(team => (
            <Popup
              content={team.name}
              key={team.id}
              position="right center"
              trigger={(
                <Menu.Item
                  name={team.id}
                  active={activeTeam === team.id}
                  onClick={handleTeamClick}
                >
                  <Avatar size={25} round src={team.avatar} name={team.name} />
                </Menu.Item>
              )}
            />
          ))}
          <Popup
            content="Add Team"
            key="add"
            position="right center"
            trigger={(
              <Menu.Item
                name="add"
                className={classes.menuAdd}
                onClick={() => {
                  setNewTeam(true);
                  setShowAddTeamModal(true);
                }}
              >
                <Icon name="add circle" />
              </Menu.Item>
            )}
          />
        </Menu>
      </div>
      <div className={classes.fullFlex}>
        <div className={classes.teamMenuDiv}>
          <Menu vertical className={classes.menu}>
            {teammates && teammates.map(teammate => (
              <Menu.Item
                key={teammate.id}
                name={teammate.id}
                active={activeTeammate === teammate.id}
                onClick={handleTeammateClick}
              >
                <Person teammate={teammate} showEmail />
              </Menu.Item>
            ))}
            <Menu.Item
              className={classes.menuAdd}
              name="add"
              position="right"
              onClick={() => {
                setNewTeammate(true);
                setShowAddTeammateModal(true);
              }}
            >
              <div className={classes.flexCenter}>
                <Icon name="add circle" />
                Add teammate
              </div>

            </Menu.Item>
            <Menu.Item
              className={classes.menuAdd}
              name="add"
              position="right"
              onClick={() => {
                setShowTeammateImportModal(true);
              }}
            >
              <div className={classes.flexCenter}>
                <Icon name="arrow down" />
                Import teammates
              </div>

            </Menu.Item>
          </Menu>
        </div>
        {activeTeam && (
          <Form className={classes.teammateForm}>
            <Segment className={classes.noMargin}>
              {activeTeamObject && (
                <div className={classes.activeTeam}>
                  <Avatar
                    size={25}
                    round
                    src={activeTeamObject.avatar}
                    name={activeTeamObject.name}
                  />
                  <Header className={classes.headerContainer}>
                    {activeTeamObject.name}
                  </Header>
                  <div className={classes.flexEnd}>
                    <Button
                      size="mini"
                      onClick={() => {
                        setNewTeam(false);
                        setShowAddTeamModal(true);
                      }}
                    >
                      Edit
                    </Button>
                    <Button size="mini" onClick={() => setShowDeleteModal(true)}>Delete</Button>
                  </div>
                </div>
              )}
            </Segment>
            <Segment placeholder className={classes.teammateSegment}>
              {activeTeammate && (
                <Profile
                  teammate={teammates.find(t => t.id === activeTeammate)}
                  teammates={teammates}
                  openTeammateForm={() => {
                    setNewTeammate(false);
                    setShowAddTeammateModal(true);
                  }}
                />
              )}
            </Segment>
          </Form>
        )}
      </div>

      <DeleteTeam
        team={activeTeamObject}
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        closeAll={() => {
          setShowDeleteModal(false);
          setActiveTeam(undefined);
          setSelectedTeam(undefined);
        }}
      />

      <TeammateForm
        setShow={setShowAddTeammateModal}
        updateTeammate={setActiveTeammate}
        show={showAddTeammateModal}
        teammateInfo={newTeammate ? {} : getActiveTeammate()}
      />

      <TeamForm
        setShow={setShowAddTeamModal}
        show={showAddTeamModal || _.isEmpty(teams)}
        team={newTeam ? {} : getActiveTeam()}
      />
      <TeammateImportForm
        setShow={setShowTeammateImportModal}
        show={showTeammateImportModal}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    team: { teams, teammates, selectedTeam },
    schedule: { events },
    main: { currentUser, customer, person },
  } = state;

  return {
    teams, teammates, events, currentUser, customer, selectedTeam, person,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  { setSelectedTeam },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
