import firebase from 'firebase';
import { GET_QUESTIONS } from '../actionTypes';

export function getQuestions(data) {
  return { type: GET_QUESTIONS, payload: data };
}

export async function getRandomQuestion(teamId) {
  const callable = await firebase.functions().httpsCallable('getRandomQuestion');
  const value = await callable(teamId);
  return value;
}

export async function fetchGlobalQuestions() {
  const callable = await firebase.functions().httpsCallable('getGlobalQuestions');
  const value = await callable();
  return value;
}

export async function saveGlobalQuestions(questions) {
  const callable = await firebase.functions().httpsCallable('saveGlobalQuestions');
  const value = await callable(questions);
  return value;
}
