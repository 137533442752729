import React from 'react';
import TourWelcome from './Welcome';
import TourTeam from './Team';

export default [
  {
    selector: '[data-tour="first-step"]',
    content: () => (<TourWelcome text="Welcome to WaterCooler!" />),
  }, {
    selector: '[data-tour="first-step"]',
    content: () => (<TourWelcome text="We'll walk you through a guided tour to get you started!" />),
  }, {
    selector: '[data-tour="first-step"]',
    content: () => (
      <TourWelcome
        text="The way it works is that you:"
        bullets={[
          'create your team',
          'create events',
          'BOOM! Team engagement!',
        ]}
      />
    ),
  }, {
    selector: '.second-step',
    content: () => (<TourTeam />),
  }, {
    selector: '[data-tour="click-team-step"]',
    content: 'By clicking the Add Team button, you can create your team and give it an avatar.',
  }, {
    selector: '[data-tour="click-teammate-step"]',
    content: 'Next, add teammates.',
  }, {
    selector: '[data-tour="first-step"]',
    content: 'After a team has been created and teammates, added, you are ready to add events.',
  }, {
    selector: '[data-tour="event-step"]',
    content: 'After a team has been created and teammates, added, you are ready to add events.',
  }, {
    selector: '[data-tour="event-add-step"]',
    content: 'Clicking on the add event button allows you to create an event for everyone to join.',
  },
];
