import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Icon, Button, Modal, Header, Form, Image, Message,
} from 'semantic-ui-react';
import { signupUser } from '../../../redux/actions/main';
import watercoolerImage from '../../../styles/images/logo.png';

const SignupForm = ({
  currentUser, currentUserError, signupUser,
}) => {
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [emailAddress, setEmailAddress] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [signingUp, setSigningUp] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (currentUserError) {
      setSigningUp(false);
      switch (currentUserError.code) {
        case 'auth/wrong-password':
          setErrorMessage('The password entered is incorrect');
          break;
        case 'auth/user-not-found':
          setErrorMessage('No account found for this email address');
          break;
        case 'auth/email-already-in-use':
          setErrorMessage(currentUserError.message);
          break;
        default:
          setErrorMessage('Unable to login at this time');
      }
    }

    if (currentUser) {
      setErrorMessage('');
      setMessage('Congratulations! An account has been built for you. Please check your email for verification.');
    }
  }, [currentUser, currentUserError]);

  const signupUserLocal = () => {
    if (password === confirmPassword) {
      setSigningUp(true);
      signupUser({
        companyName, name, email: emailAddress, password,
      });
    } else {
      setErrorMessage('The passwords provided do not match');
    }
  };

  return (
    <Form>
      <Modal
        open
        basic
        dimmer
        size="mini"
      >
        <Header icon>
          <div style={{
            display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', color: 'white',
          }}
          >
            <div>
              <Image src={watercoolerImage} size="small" wrapped />
              <div style={{ paddingTop: 20 }}><i>Build your team and engage!</i></div>
            </div>
          </div>
        </Header>
        {errorMessage && <Message error content={errorMessage} />}
        {message && <Message floating positive content={message} />}

        {!message && (
        <Modal.Content style={{ display: 'flex', flex: 1 }}>
          <>
            <Form style={{ flex: 1 }} inverted onSubmit={() => signupUserLocal()}>
              <Form.Group>
                <Form.Input
                  width={16}
                  inverted
                  label="Company Name"
                  name="companyName"
                  value={companyName}
                  onChange={(e, d) => setCompanyName(d.value)}
                  placeholder="Enter your company name..."
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={16}
                  inverted
                  label="Name"
                  name="name"
                  value={name}
                  onChange={(e, d) => setName(d.value)}
                  placeholder="Enter your name..."
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={16}
                  inverted
                  label="Email"
                  name="email"
                  value={emailAddress}
                  onChange={(e, d) => setEmailAddress(d.value)}
                  placeholder="Enter your email..."
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={16}
                  label="Password"
                  name="password"
                  type="password"
                  inverted
                  value={password}
                  onChange={(e, d) => setPassword(d.value)}
                  placeholder="Enter your password..."
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={16}
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  inverted
                  value={confirmPassword}
                  onChange={(e, d) => setConfirmPassword(d.value)}
                  placeholder="Enter your password again..."
                />
              </Form.Group>
              <Form.Group>
                <Button color="green" fluid loading={signingUp} disabled={signingUp} onClick={() => signupUserLocal()}>
                  <Icon name="checkmark" />
                  Signup!
                </Button>

              </Form.Group>
              <Form.Group>
                <div style={{
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', flex: 1,
                }}
                >
                  <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                    Already have an account?&nbsp;
                    <a href="/login">Login!</a>
                  </div>
                  <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                    Invited to a team?&nbsp;
                    <a href="/join">Join!</a>
                  </div>
                </div>
              </Form.Group>

            </Form>
          </>
        </Modal.Content>
        )}
      </Modal>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const {
    main: { customer, currentUser, currentUserError },
  } = state;

  return { customer, currentUser, currentUserError };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  { signupUser },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
