import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './Dashboard.css';
import { Dimmer, Loader } from 'semantic-ui-react';
import moment from 'moment';
import { fetchTeams, fetchTeammates, updateSelectedTeam, fetchUnapprovedTeams } from '../../redux/actions/team';
import { fetchCustomer, fetchPerson, finishLoading, findMyCustomers, getCustomer } from '../../redux/actions/main';
import { fetchEvents } from '../../redux/actions/schedule';
import { fetchSettings, saveSettings } from '../../redux/actions/settings';
import TeamsCard from '../cards/teams';
import EventsCard from '../cards/events';
import LeaderBoardCard from '../cards/leaderboard';
import ChartCard from '../cards/charts';
import steps from '../tour/steps';
import Tour from 'reactour'
import _ from 'lodash';
import TeammateApproval from '../modals/teammateApproval';
import { convertFirebaseTimestampToDate } from '../../api/helpers';

const Dashboard = React.memo(({
  currentUser, customer, person, teams, unapprovedTeams, selectedTeam, schedule, teammates, settings, loading, loadingTeammates,
  findMyCustomers, fetchTeams, fetchSettings, fetchUnapprovedTeams, updateSelectedTeam, finishLoading, fetchTeammates,
  fetchEvents, saveSettings,
}) => {
  const [leaders, setLeaders] = useState([]);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [events, setEvents] = useState(undefined);
  const [showTeammateApprovalModal, setShowTeammateApprovalModal] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    if (currentUser) {
      findMyCustomers(currentUser.email);
    }
  }, [currentUser, findMyCustomers]);

  useEffect(() => {
    if (!_.isEmpty(customer)) {
      fetchTeams(customer.id);
      fetchSettings(customer.id);
      fetchUnapprovedTeams(currentUser.email);
    }
  }, [customer, currentUser.email, fetchSettings, fetchTeams, fetchUnapprovedTeams]);

  useEffect(() => {
    if (!_.isEmpty(customer) && !_.isEmpty(person)) {
      setIsOwner(customer.email === person.email);
    }
  }, [customer, person]);

  useEffect(() => {
    if (!_.isEmpty(teams)) {
      let selectedTeam = teams.filter(team => team.id === localStorage.getItem('teamId'));

      if (_.isEmpty(selectedTeam)) {
        selectedTeam = teams[0];
        localStorage.getItem('teamId', selectedTeam.id);
      }

      updateSelectedTeam(selectedTeam);
    } else if (Array.isArray(teams) && _.isEmpty(teams) && !_.isEmpty(customer)) {
      finishLoading();
    }
  }, [teams, customer, finishLoading, updateSelectedTeam]);

  useEffect(() => {
    if (!_.isEmpty(unapprovedTeams)) {
      setTimeout(() => setShowTeammateApprovalModal(true), 1000)
    }
  }, [unapprovedTeams]);

  useEffect(() => {
    fetchTeammates(selectedTeam?.id);
    fetchEvents(selectedTeam?.id);
  }, [selectedTeam, fetchEvents, fetchTeammates]);

  useEffect(() => {
    if (!_.isEmpty(selectedTeam)) {
      fetchEvents(selectedTeam?.id);
    }
  }, [schedule.updatedEventsDate, fetchEvents, selectedTeam]);

  useEffect(() => {
    if (schedule?.events !== undefined) {
      const dateEvents = schedule?.events?.map(event => {
        return {
          id: event.id,
          title: `${event.name}  →  ${event.question}`,
          date: convertFirebaseTimestampToDate(event.eventDate).format()
        };
      });
      setEvents(dateEvents);
      finishLoading();
    }
  }, [schedule, finishLoading]);

  useEffect(() => {
    if (teammates && teammates.length > 2) {
      let newLeaders = [];
      for (let x = 0; x < 3; x++) {
        const data = teammates[x];
        newLeaders = [...newLeaders, data];
      }
      setLeaders(newLeaders);
    } else {
      setLeaders(null);
    }
  }, [teammates]);

  useEffect(() => {
    if (settings && !loading && !settings.finishedTour) {
      setTimeout(() => {
        setIsTourOpen(true);
      }, 1000);
    }
  }, [loading, settings])

  const visitData = [];
  const beginDay = new Date().getTime();
  for (let i = 0; i < 20; i += 1) {
    visitData.push({
      x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format('YYYY-MM-DD'),
      y: Math.floor(Math.random() * 100) + 10,
    });
  }

  const finishTourOff = () => {
    setIsTourOpen(false);
    saveSettings({ id: settings.id, finishedTour: true })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1, height: '100%' }}>
      <Tour
        badgeContent={(curr, tot) => `${curr} of ${tot}`}
        steps={steps}
        isOpen={isTourOpen}
        disableInteraction
        rounded={10}
        onRequestClose={() => finishTourOff()}
      />
      {loading && <Dimmer active>
        <Loader size='massive' indeterminate>Loading all of your team information</Loader>
      </Dimmer>}
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1, height: '100%', margin: '1em' }} data-tour="click-team-step__observe">
        <div style={{ display: 'flex', flex: 1, marginRight: isOwner ? '1em' : 0, }} className='first-step'>
          <LeaderBoardCard leaders={leaders} />
        </div>
        {isOwner && <div style={{ display: 'flex', flex: 1.5, marginLeft: '1em' }} data-tour="event-step">
          <ChartCard />
        </div>}
      </div>
      <div style={{ display: 'flex', flex: 1.2, height: '100%', margin: '1em' }}>
        <div style={{ display: 'flex', flex: 1, height: '100%', marginRight: '1em' }} className='second-step'>
          <TeamsCard loading={loadingTeammates} teammates={teammates} teams={teams} isOwner={isOwner} />
        </div>
        <div style={{ display: 'flex', flex: 1, height: '100%', marginLeft: '1em' }} data-tour="event-step">
          <EventsCard events={events} loading={schedule?.eventsProcessing} teammates={teammates} schedule={schedule} isOwner={isOwner} />
        </div>
      </div>
      <TeammateApproval setShow={setShowTeammateApprovalModal} show={showTeammateApprovalModal} unapprovedTeams={unapprovedTeams} />
    </div>
  );
});

const mapStateToProps = (state, ownProps) => {
  const {
    team: { teams, teammates, selectedTeam, unapprovedTeams, loadingTeammates },
    schedule,
    settings: { settings },
    main: { currentUser, customer, loading, person, myCustomers }
  } = state;

  return {
    teams,
    teammates,
    schedule,
    currentUser,
    customer,
    selectedTeam,
    loading,
    settings,
    person,
    unapprovedTeams,
    myCustomers,
    loadingTeammates,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchTeams,
      fetchTeammates,
      fetchCustomer,
      fetchPerson,
      fetchEvents,
      updateSelectedTeam,
      fetchSettings,
      finishLoading,
      saveSettings,
      findMyCustomers,
      fetchUnapprovedTeams,
      getCustomer
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
