import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Icon, Button, Modal, Header, Form, Image,
} from 'semantic-ui-react';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import { forgotPassword } from '../../../redux/actions/main';
import watercoolerImage from '../../../styles/images/logo.png';
import 'react-semantic-toasts/styles/react-semantic-alert.css';

const ForgotPasswordForm = ({
  resetPasswordTime, forgotPassword,
}) => {
  const [emailAddress, setEmailAddress] = useState('');

  useEffect(() => {
    if (resetPasswordTime) {
      toast({ title: 'Email sent', description: <p>Email has been sent to reset your password.</p> });
    }
  }, [resetPasswordTime]);

  return (

    <Form>
      <Modal open basic dimmer="blurred" size="mini">
        <Header icon>
          <div style={{
            display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', color: 'white',
          }}
          >
            <div>
              <Image src={watercoolerImage} size="small" wrapped />
            </div>
          </div>
        </Header>

        <Modal.Content style={{ display: 'flex', flex: 1 }}>
          <>
            <Form
              style={{ width: 500 }}
              autoComplete="off"
              widths="equal"
              inverted
              onSubmit={() => {
                if (emailAddress) {
                  forgotPassword(emailAddress);
                }
              }}
            >
              <SemanticToastContainer />
              <Form.Group>
                <Form.Input
                  width={16}
                  inverted
                  label="Email"
                  name="email"
                  autoComplete="off"
                  value={emailAddress}
                  onChange={(e, data) => setEmailAddress(data.value)}
                  placeholder="Enter your email..."
                />
              </Form.Group>
              <Form.Group>
                <Button color="green" fluid onClick={() => forgotPassword(emailAddress)}>
                  <Icon name="checkmark" />
                  Reset Password
                </Button>
              </Form.Group>
              <Form.Group>
                <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                  <a href="/login">Ready to login?</a>
                </div>
              </Form.Group>
            </Form>
          </>
        </Modal.Content>
      </Modal>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const {
    main: { resetPasswordTime },
  } = state;

  return { resetPasswordTime };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  { forgotPassword },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
