/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import {
  Form, Input, Button, Select, Icon, Card, Menu, Popup, Message, Dimmer,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import ImageUploading from 'react-images-uploading';
import Avatar from 'react-avatar';
import MaskedInput from 'react-text-mask';
import _ from 'lodash';
import SubscriptionForm from '../../../modals/subscription';
import { buildData, fetchSettings, saveSettings } from '../../../../redux/actions/settings';
import { fetchCustomer, saveCustomer } from '../../../../redux/actions/main';
import { getStripeCustomer, getProducts, getPrices } from '../../../../redux/actions/payment';
import { saveEvent } from '../../../../redux/actions/schedule';
import useStyles from './styles';

const toolsOptions = [
  { key: 'g', text: 'Google Workspace', value: 'google' },
  { key: 'm', text: 'Microsoft Office 365', value: 'office365' },
  { key: 'o', text: 'Microsoft Outlook', value: 'outlook' },
  { key: 'y', text: 'Yahoo', value: 'yahoo' },
  { key: 'x', text: 'Other', value: 'other' },
];

const PersonalInfo = ({
  customer, settings, fetchSettings, saveCustomer, buildData,
}) => {
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [avatar, setAvatar] = useState('');
  const [toolSuite, setToolSuite] = useState('');
  const [images, setImages] = useState([]);
  const [newAvatar, setNewAvatar] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [stripeCustomer, setStripeCustomer] = useState(undefined);
  const [products, setProducts] = useState(undefined);
  const [prices, setPrices] = useState(undefined);
  const [currentProduct, setCurrentProduct] = useState(undefined);
  const [currentSubscription, setCurrentSubscription] = useState(undefined);

  const styles = useStyles();

  useEffect(() => {
    getStripeCustomer(customer).then(currentCustomer => {
      setStripeCustomer(currentCustomer);
    });
    getProducts().then(productdata => setProducts(productdata.data));
    getPrices().then(priceData => setPrices(priceData.data.data));
  }, [customer]);

  useEffect(() => {
    if (stripeCustomer && products) {
      const customerSubscription = _.get(stripeCustomer, 'data.subscriptions.data[0]', undefined);
      setCurrentSubscription(customerSubscription);
      let cp = products.filter(p => p.metadata.title === 'Free');

      if (customerSubscription && customerSubscription.plan
        && customerSubscription.plan.product && products) {
        cp = products.filter(p => p.id === customerSubscription.plan.product);
      }
      setCurrentProduct(!_.isEmpty(cp) ? cp[0] : undefined);
    }
  }, [products, stripeCustomer, showForm]);

  useEffect(() => {
    if (!_.isEmpty(customer)) {
      setName(customer.name);
      setEmail(customer.email);
      setPhone(customer.phone);
      setCompanyName(customer.companyName);
      setAvatar(customer.avatar);
    }

    if (!_.isEmpty(settings)) {
      setToolSuite(settings.toolSuite);
    } else if (!_.isEmpty(customer)) {
      fetchSettings(customer.id);
    }
  }, [customer, fetchSettings, settings]);

  const handleSave = async () => {
    if (!_.isEmpty(customer)) {
      const customerId = customer.id;
      const customerData = {
        id: customerId,
        name,
        companyName,
        email,
        phone,
        avatar,
      };
      await saveCustomer(customerData, newAvatar);

      const settingsData = {
        toolSuite,
      };
      if (settings && settings.id) {
        settingsData.id = settings.id;
      }

      await saveSettings(settingsData);
      setTimeout(() => toast({ title: 'Saved', description: <p>Settings have been saved!</p> }), 500);
    }
  };

  const onChange = (imageList) => {
    let newImageList = imageList;
    if (newImageList && newImageList.length > 1) {
      newImageList = newImageList.slice(newImageList.length - 1);
    }

    if (newImageList && newImageList.length > 0) {
      setImages(newImageList);
      setNewAvatar(newImageList[0].data_url);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.fullWidth}>
        <Card.Content>
          <Card.Header>Organization</Card.Header>
        </Card.Content>
        <Card.Content>
          <Form className={styles.form}>
            <SemanticToastContainer />
            <Form.Group widths="16">
              <Form.Field className={styles.formField}>
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={100}
                  dataURLKey="data_url"
                >
                  {({
                    onImageUpload,
                    onImageRemoveAll,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">

                      <div className={styles.drag} {...dragProps}>
                        <div className={styles.avatarContainer}>
                          <Avatar size={50} round src={newAvatar || avatar} name={companyName} />
                          <div className={styles.popup}>
                            <Popup
                              position="bottom left"
                              trigger={
                                <Icon name="ellipsis vertical" />
                              }
                              content={(
                                <Menu icon vertical>
                                  <Menu.Item
                                    name="gamepad"
                                    onClick={onImageUpload}
                                  >
                                    <Icon name="upload" />
                                  </Menu.Item>

                                  <Menu.Item
                                    name="remove"
                                    onClick={() => {
                                      onImageRemoveAll();
                                      setNewAvatar('');
                                      setImages([]);
                                      setAvatar('');
                                    }}
                                  >
                                    <Icon name="remove" />
                                  </Menu.Item>
                                </Menu>
)}
                              on="click"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </ImageUploading>
              </Form.Field>

              <Form.Field
                id="form-input-control-company-name"
                control={Input}
                value={companyName}
                onChange={(e, data) => setCompanyName(data.value)}
                label={<label>Organization</label>}
                width={16}
                placeholder="What's the name of your organization..."
              />

            </Form.Group>
            <Form.Field
              id="form-input-control-customer-name"
              control={Input}
              value={name}
              onChange={(e, data) => setName(data.value)}
              label="Contact Name"
              className={styles.black}
              placeholder="What's your name..."
            />
            <Form.Group widths="equal">
              <Form.Field
                id="form-input-control-customer-email"
                control={Input}
                value={email}
                onChange={(e, data) => setEmail(data.value)}
                label="Email Address"
                placeholder="Email address..."
              />
              <Form.Input
                label="Phone Number"
                name="phone"
                value={phone}

                children={(
                  <MaskedInput
                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    placeholder="(123) 456-7890"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                )}
              />

              <Form.Select
                control={Select}
                options={toolsOptions}
                label="Productivity Tools"
                placeholder="Who do you email through..."
                onChange={(e, data) => setToolSuite(data.value)}
                value={toolSuite}
                search
                searchInput={{ id: 'form-select-control-tools' }}
              />
            </Form.Group>
          </Form>
        </Card.Content>
        <Card.Content extra textAlign="right">
          <Button color="blue" inverted onClick={() => buildData()}>
            <Icon name="cloud upload" />
            {' '}
            Export
          </Button>
          <Button color="green" inverted onClick={handleSave}>
            <Icon name="checkmark" />
            {' '}
            Save
          </Button>
        </Card.Content>
      </Card>
      <Dimmer.Dimmable
        as={Card}
        dimmed={(!stripeCustomer || !products || !currentProduct)}
        blurring
        elevation={3}
        className={styles.fullWidth}
      >
        <Card.Content>
          <Card.Header>Subscription</Card.Header>
        </Card.Content>
        <Card.Content>
          {stripeCustomer && products && currentProduct && (
          <Message>
            <Message.Header>
              <div className={styles.flex}>
                <div className={styles.flexGrow}>
                  {currentProduct.metadata.title}
                  {' '}
                  {currentSubscription && (
                    <div className={styles.font14}>
                      {currentSubscription.status}
                    </div>
                  )}
                </div>
                <div>
                  <Button color="red" inverted onClick={() => setShowForm(true)}>Update</Button>
                </div>
              </div>
            </Message.Header>
            <Message.List>
              <Message.Item>
                <span dangerouslySetInnerHTML={{ __html: currentProduct.metadata.line1 }} />
              </Message.Item>
              <Message.Item>
                <span dangerouslySetInnerHTML={{ __html: currentProduct.metadata.line2 }} />
              </Message.Item>
              <Message.Item>
                <span dangerouslySetInnerHTML={{ __html: currentProduct.metadata.line3 }} />
              </Message.Item>
            </Message.List>
          </Message>
          )}
        </Card.Content>
      </Dimmer.Dimmable>
      <SubscriptionForm
        setShow={setShowForm}
        show={showForm}
        products={products}
        prices={prices}
        currentProduct={currentProduct}
        stripeCustomer={stripeCustomer}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    main: { customer, currentUser },
    schedule,
    team: { selectedTeam },
    settings: { settings },
  } = state;

  return {
    settings, customer, currentUser, schedule, selectedTeam,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    fetchSettings, saveSettings, saveEvent, fetchCustomer, saveCustomer, buildData,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
