import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Icon, Button, Modal, Header,
} from 'semantic-ui-react';
import { removeTeam } from '../../../redux/actions/team';
import '../styles/teams.css';

const RemoveTeamModal = ({
  removeTeam, closeAll, show, team, setShow,
}) => {
  const handleDelete = () => {
    removeTeam(team);
    if (closeAll) {
      closeAll();
    }
  };

  return (
    <Modal
      basic
      open={show}
      size="small"
    >
      <Header icon>
        Delete
        {' '}
        {team?.name}
      </Header>
      <Modal.Content>
        <p>
          Are you sure you want to remove
          {' '}
          {team?.name}
          {' '}
          as a team?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={() => setShow(false)}>
          <Icon name="remove" />
          {' '}
          No
        </Button>
        <Button color="green" inverted onClick={handleDelete}>
          <Icon name="checkmark" />
          {' '}
          Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators(
  { removeTeam },
  dispatch,
);

export default connect(null, mapDispatchToProps)(RemoveTeamModal);
