import { createUseStyles } from 'react-jss';

export default createUseStyles({
  item: {
    color: '#a0a0a0',
    textAlign: 'right',
  },
  itemLeft: {
    color: '#a0a0a0',
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
    padding: 40,
    flex: 1,
  },
  bodyContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  nameContainer: {
    display: 'flex',
    fontSize: 24,
    fontWeight: '800',
    paddingLeft: 20,
  },
  name: {
    flex: 1,
  },
  description: {
    fontSize: 16,
    fontWeight: '400',
    paddingLeft: 25,
  },
  gridWrapper: {
    margin: 5,
  },
  spacer: {
    display: 'flex',
    justifyContent: 'center',
  },
  awardContainer: {
    display: 'flex',
    flex: 0,
    flexDirection: 'column',
    height: 100,
    justifyContent: 'flex-end',
  },
  segmentWrapper: {
    flex: 0,
  },
  segment: {
    margin: 0,
  },
});
