import firebase from 'firebase';
import {
  GET_TEAMMATES,
  REQUEST_TEAMMATES,
  REQUEST_TEAMS,
  GET_TEAMS,
  SET_SELECTED_TEAM,
  GET_UNAPPROVED_TEAMS,
  UPDATE_TEAMMATE_ANSWER,
  UPDATE_HAS_ACCOUNT,
  UPDATE_HAS_TEAMS,
} from '../actionTypes';
import { fetchEvents } from './schedule';

export function getTeammates(data) {
  return { type: GET_TEAMMATES, payload: data };
}

export function getTeams(data) {
  return { type: GET_TEAMS, payload: data };
}

export function requestTeams() {
  return { type: REQUEST_TEAMS };
}

export function requestTeammates() {
  return { type: REQUEST_TEAMMATES };
}

export function setSelectedTeam(data) {
  return { type: SET_SELECTED_TEAM, payload: data };
}

export function getUnapprovedTeams(data) {
  return { type: GET_UNAPPROVED_TEAMS, payload: data };
}

export function getTeammateAnswer(data) {
  return { type: UPDATE_TEAMMATE_ANSWER, payload: data };
}

export function updateHasAccount(data) {
  return { type: UPDATE_HAS_ACCOUNT, payload: data };
}

export function updateHasTeams(data) {
  return { type: UPDATE_HAS_TEAMS, payload: data };
}

export function fetchTeammates(teamId) {
  return dispatch => {
    dispatch(requestTeammates());
    if (teamId) {
      const callable = firebase.functions().httpsCallable('getTeammates');
      callable(teamId).then(data => {
        dispatch(getTeammates(data.data));
      });
    } else {
      dispatch(getTeammates(null));
    }
  };
}

export const fetchTeammatesDirect = async (teamId) => {
  if (teamId) {
    const callable = firebase.functions().httpsCallable('getTeammates');
    const value = await callable(teamId);
    return value;
  }
  return null;
};

export const fetchTeammateDirect = async (teammateId) => {
  if (teammateId) {
    const callable = firebase.functions().httpsCallable('getTeammate');
    const value = await callable(teammateId);
    return value;
  }
  return null;
};

export function updateSelectedTeam(data) {
  return dispatch => {
    const eventsRef = firebase.database().ref('/events').child(data.id);
    eventsRef.on('value', () => {
      dispatch(fetchEvents(data.id));
    });
    dispatch(setSelectedTeam(data));
  };
}

export function saveTeammateDirect(teammate) {
  const callable = firebase.functions().httpsCallable('saveIndividualTeammate');
  return callable(teammate);
}

export function saveTeammateAvatar(avatar, teammate) {
  return dispatch => {
    firebase.storage().ref('/avatars/teammate').child(teammate.id)
      .putString(avatar, 'data_url')
      .then(() => {
        firebase.storage().ref(`/avatars/teammate/${teammate.id}`).getDownloadURL().then(url => {
          const teammateData = { ...teammate, avatar: url };
          saveTeammateDirect(teammateData).then(teammateInfo => {
            dispatch(fetchTeammates(teammateInfo.data.teamId));
          });
        });
      });
  };
}

export function saveTeammate(teammate, avatar) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('saveIndividualTeammate');
    callable(teammate).then(data => {
      if (avatar) {
        dispatch(saveTeammateAvatar(avatar, data.data));
      } else {
        dispatch(fetchTeammates(data.data.teamId));
      }
    });
  };
}

export async function resendInvite(teammate) {
  const callable = firebase.functions().httpsCallable('resendInvite');
  const response = await callable(teammate);
  return response;
}

export function doesAccountExist(email) {
  return dispatch => {
    const personCallable = firebase.functions().httpsCallable('doesTeammateHaveAccount');
    personCallable(email).then(hasAccount => {
      dispatch(updateHasAccount(hasAccount.data));
    });
  };
}

export function doesTeammateHaveTeams(email) {
  return dispatch => {
    const personCallable = firebase.functions().httpsCallable('doesTeammateHaveTeams');
    personCallable(email).then(hasTeams => {
      dispatch(updateHasTeams(hasTeams.data));
    });
  };
}

export function fetchUnapprovedTeams(email) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('fetchUnapprovedTeams');
    callable(email).then(data => {
      dispatch(getUnapprovedTeams(data.data));
    });
  };
}

export function approveTeam(email, teamId, approved) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('approveTeam');
    callable({ email, teamId, approved }).then(data => {
      dispatch(fetchUnapprovedTeams(email));
      dispatch(fetchTeammates(teamId));
      dispatch(getTeammateAnswer(data.data));
    });
  };
}

export function fetchTeams(customerId) {
  return dispatch => {
    dispatch(requestTeams());
    const callable = firebase.functions().httpsCallable('getTeams');
    callable(customerId).then(data => {
      dispatch(getTeams(data.data));
    });
  };
}

export function saveTeamDirect(team) {
  const callable = firebase.functions().httpsCallable('saveIndividualTeam');
  return callable(team);
}

export function saveTeamAvatar(avatar, team) {
  return dispatch => {
    firebase.storage().ref('/avatars/team').child(team.id)
      .putString(avatar, 'data_url')
      .then(() => {
        firebase.storage().ref(`/avatars/team/${team.id}`).getDownloadURL().then(url => {
          const teamData = { ...team, avatar: url };
          saveTeamDirect(teamData).then(teamInfo => {
            dispatch(fetchTeams(teamInfo.data.customerId));
          });
        });
      });
  };
}

export function saveTeam(team, avatar) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('saveIndividualTeam');
    callable(team).then(data => {
      if (avatar) {
        dispatch(saveTeamAvatar(avatar, data.data));
      } else {
        dispatch(fetchTeams(data.data.customerId));
        dispatch(setSelectedTeam(data.data));
      }
    });
  };
}

export function removeTeam(team) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('removeTeam');
    callable(team.id).then(() => {
      dispatch(fetchTeams(team.customerId));
    });
  };
}

export function removeTeammate(teammate) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('removeTeammate');
    callable(teammate.id).then(() => {
      dispatch(fetchTeammates(teammate.teamId));
    });
  };
}
