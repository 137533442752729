import { createUseStyles } from 'react-jss';

export default createUseStyles({
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },

  popup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  img: {
    boxShadow: '5px 5px 10px #A8B5BF',
    backgroundColor: 'aliceblue',
  },
});
