import firebase from 'firebase';

export async function buildOverviewReportData(teamId) {
  const callable = firebase.functions().httpsCallable('buildOverviewReportData');
  const value = await callable(teamId);
  return value;
}

export async function buildLeaderboard(teamId) {
  const callable = firebase.functions().httpsCallable('buildLeaderboard');
  const value = await callable(teamId);
  return value;
}
