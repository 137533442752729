import React from 'react';
import { connect } from 'react-redux';
import './login.css';
import { Grid } from 'semantic-ui-react';
import LoginForm from '../modals/login';
import WaterCoolerParticles from '../components/particles';

const Login = React.memo(() => (
  <div style={{ height: '110vh', backgroundImage: 'linear-gradient(to right, #99cfff, #66b8ff)' }}>
    <WaterCoolerParticles />
    <Grid columns={1} centered relaxed style={{ backgroundImage: 'linear-gradient(to right, #99cfff, #66b8ff)' }}>
      <Grid.Column stretched textAlign="center" style={{ paddingTop: 200 }}>
        <LoginForm />
      </Grid.Column>
    </Grid>
  </div>
));

const mapStateToProps = (state) => {
  const {
    team: { teams, teammates, selectedTeam },
    schedule,
    settings: { settings },
    main: { currentUser, customer },
  } = state;

  return {
    teams, teammates, schedule, currentUser, customer, selectedTeam, settings,
  };
};

export default connect(mapStateToProps, null)(Login);
