import firebase from 'firebase';
import { GET_SETTINGS } from '../actionTypes';

export function getSettings(data) {
  return { type: GET_SETTINGS, payload: data };
}

export function fetchSettings(customerId) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('getSettings');
    callable(customerId).then(data => {
      dispatch(getSettings(data.data));
    });
  };
}

export function buildData() {
  return () => {
    const callable = firebase.functions().httpsCallable('eventAnalysis');
    return callable();
  };
}

export function saveSettings(data) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('saveSettings');
    callable(data).then(data => {
      dispatch(getSettings(data.data));
    });
  };
}
