import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Routes from './Routes';
import Main from './Main';
import configureStore from './redux/configureStore';

// import authenticatorConfig from './config/authenticatorConfig';
const store = configureStore();

const App = () => (
  <ReduxProvider store={store}>
    <BrowserRouter>
      <Main>
        <Routes />
      </Main>
    </BrowserRouter>
  </ReduxProvider>
);

export default App;
