import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Icon, Button, Modal, Header, Form, Popup, Menu, Transition,
} from 'semantic-ui-react';
import Avatar from 'react-avatar';
import _ from 'lodash';
import ImageUploading from 'react-images-uploading';
import MaskedInput from 'react-text-mask';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import { saveTeammate, removeTeammate, resendInvite } from '../../../redux/actions/team';
import 'react-semantic-toasts/styles/react-semantic-alert.css';

const timeLimit = 200;

const TeammateForm = ({
  teammateInfo, removeTeammate, selectedTeam, customer, setShow, show, saveTeammate,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [nickname, setNickname] = useState('');
  const [birthday, setBirthday] = useState('');
  const [hobbies, setHobbies] = useState('');
  const [webpage, setWebpage] = useState('');
  const [location, setLocation] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [description, setDescription] = useState('');
  const [notes, setNotes] = useState('');
  const [avatar, setAvatar] = useState('');
  const [newAvatar, setNewAvatar] = useState(undefined);
  const [images, setImages] = useState([]);
  const [emailCount, setEmailCount] = useState(0);

  useEffect(() => {
    if (!_.isEmpty(teammateInfo)) {
      setName(teammateInfo?.name);
      setEmail(teammateInfo?.email);
      setPhoneNumber(teammateInfo?.phoneNumber);
      setDescription(teammateInfo?.description);
      setAvatar(teammateInfo?.avatar);
      setNickname(teammateInfo?.nickname);
      setNotes(teammateInfo?.notes);
      setHobbies(teammateInfo?.hobbies);
      setWebpage(teammateInfo?.webpage);
      setLocation(teammateInfo?.location);
    } else {
      setName('');
      setEmail('');
      setPhoneNumber('');
      setDescription('');
      setAvatar('');
      setNewAvatar('');
      setHobbies('');
      setNotes('');
      setBirthday('');
      setNickname('');
      setLocation('');
      setWebpage('');
    }
  }, [teammateInfo]);

  const handleDelete = () => {
    removeTeammate(teammateInfo);
  };

  const handleSubmit = async () => {
    const data = {
      name,
      email,
      phoneNumber,
      description,
      avatar,
      hobbies,
      notes,
      birthday,
      nickname,
      location,
      webpage,
    };
    if (teammateInfo?.id) {
      data.id = teammateInfo.id;
    } else {
      data.approved = false;
    }
    data.teamId = selectedTeam.id;
    data.customerId = customer.id;
    await saveTeammate(data, newAvatar);
    setShow(false);
  };

  useEffect(() => {
    if (show) {
      setModalShow(show);
    } else {
      setTimeout(() => {
        setModalShow(show);
      }, timeLimit - 10);
    }
  }, [show]);

  const resendInviteCall = async () => {
    if (emailCount < 1) {
      await resendInvite({ email, teamId: teammateInfo.teamId });
      toast({ title: 'Email sent!', description: <p>We resent an invite email!</p> });
      setEmailCount(emailCount + 1);
    } else {
      toast({ title: 'Come on!', description: <p>You&apos;re only going to get us designated to the spam folder.</p> });
    }
  };

  const onChange = (il) => {
    let imageList = il;

    if (imageList && imageList.length > 1) {
      imageList = imageList.slice(imageList.length - 1);
    }

    if (imageList && imageList.length > 0) {
      setImages(imageList);
      setNewAvatar(imageList[0].data_url);
    }
  };

  return (
    <Form>
      <Transition visible={show} animation="fade" duration={{ hide: timeLimit, show: timeLimit }}>
        <Modal
          onClose={() => setShow(false)}
          onOpen={() => setShow(true)}
          open={modalShow}
          basic
          size="small"
          closeOnDimmerClick={false}
        >
          <SemanticToastContainer />
          <Modal.Content style={{ display: 'flex', flex: 1 }}>
            <>
              <Form style={{ flex: 1, paddingLeft: 20 }} onSubmit={handleSubmit}>
                <Form.Group>
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={100}
                    dataURLKey="data_url"
                  >
                    {({
                      onImageUpload,
                      onImageRemoveAll,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">

                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          <div style={{
                            display: 'flex', id: 'hi!', alignSelf: 'flex-end', zIndex: 9996,
                          }}
                          >
                            <Avatar size={50} round src={newAvatar || avatar} name={name} />
                            <div style={{
                              position: 'relative', alignSelf: 'flex-start', left: 0, bottom: 0,
                            }}
                            >
                              <Popup
                                id="yopop"
                                style={{ zIndex: 9999 }}
                                position="bottom left"
                                trigger={
                                  <Icon name="ellipsis vertical" />
                                }
                                content={(
                                  <span id="hahaha">
                                    <Menu icon vertical id="test!!!" style={{ zIndex: 9999 }}>
                                      <Menu.Item
                                        name="gamepad"
                                        onClick={onImageUpload}
                                      >
                                        <Icon name="upload" />
                                      </Menu.Item>

                                      <Menu.Item
                                        name="remove"
                                        onClick={() => {
                                          onImageRemoveAll();
                                          setNewAvatar('');
                                          setImages([]);
                                          setAvatar('');
                                        }}
                                      >
                                        <Icon name="remove" />
                                      </Menu.Item>
                                    </Menu>
                                  </span>
                                )}
                                on="click"
                              />
                            </div>

                          </div>
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                  <Form.Input
                    width={16}
                    label="Name"
                    name="name"
                    value={name}
                    required
                    onChange={(e, d) => setName(d.value)}
                    placeholder="name"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Input
                    width={16}
                    label="Email Address"
                    name="email"
                    required
                    value={email}
                    placeholder="Email Address..."
                    onChange={(e, d) => setEmail(d.value)}
                    type="email"
                  />
                  <Form.Input
                    width={16}
                    label="Nickname"
                    name="nickname"
                    value={nickname}
                    placeholder="Nickname..."
                    onChange={(e, d) => setNickname(d.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Input
                    label="Phone Number"
                    name="phone"
                    value={phoneNumber}
                    width={16}

                    children={(
                      <MaskedInput
                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        placeholder="(123) 456-7890"
                        value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                        }}
                      />
                    )}
                  />

                  <Form.Input
                    label="Birthday"
                    name="birthday"
                    value={birthday}
                    width={16}

                    children={(
                      <MaskedInput
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        placeholder="01/01/2022"
                        value={birthday}
                        onChange={(e) => {
                          setBirthday(e.target.value);
                        }}
                      />
                    )}
                  />

                </Form.Group>
                <Form.Group>
                  <Form.Input
                    width={16}
                    label="Hobbies"
                    name="hobbies"
                    required
                    value={hobbies}
                    placeholder="Hobbies..."
                    onChange={(e, d) => setHobbies(d.value)}
                  />
                  <Form.Input
                    width={16}
                    label="Webpage"
                    name="webpage"
                    value={webpage}
                    placeholder="Webpage..."
                    onChange={(e, d) => setWebpage(d.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Input
                    width={16}
                    label="Location"
                    name="location"
                    required
                    value={location}
                    placeholder="Location..."
                    onChange={(e, d) => setLocation(d.value)}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Input
                    width={16}
                    label="Description"
                    name="description"
                    required
                    value={description}
                    placeholder="Description..."
                    onChange={(e, d) => setDescription(d.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Input
                    width={16}
                    label="Notes"
                    name="notes"
                    required
                    value={notes}
                    placeholder="Notes..."
                    onChange={(e, d) => setNotes(d.value)}
                  />
                </Form.Group>
              </Form>
            </>
          </Modal.Content>
          <Modal.Actions>
            <div style={{ display: 'flex' }}>
              {!teammateInfo?.id && (<div style={{ alignSelf: 'center', fontSize: 12 }}><i>After adding a teammate, an email will be sent for their approval to the team.</i></div>)}
              {teammateInfo?.id && (
              <Button floated="left" color="red" onClick={() => setShowDeleteModal(true)}>
                <Icon name="delete" />
                {' '}
                Delete
              </Button>
)}
              {teammateInfo?.id && teammateInfo?.approved === false && (
              <Button floated="left" color="green" basic onClick={resendInviteCall}>
                <Icon name="send" />
                {' '}
                Resend Invite
              </Button>
)}
              <div style={{ flex: 1 }}>
                <Button basic color="red" onClick={() => setShow(false)}>
                  <Icon name="remove" />
                  {' '}
                  Cancel
                </Button>
                <Button color="green" type="submit" onClick={handleSubmit}>
                  <Icon name="checkmark" />
                  {' '}
                  Save
                </Button>
              </div>
            </div>
          </Modal.Actions>
          <Modal
            basic
            open={showDeleteModal}
            size="small"
          >
            <Header icon>
              <Icon name="user delete" />
              Delete
              {' '}
              {teammateInfo?.name}
            </Header>
            <Modal.Content>
              <p>
                Are you sure you want to remove
                {' '}
                {teammateInfo?.name}
                {' '}
                from your team?
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button basic color="red" inverted onClick={() => setShowDeleteModal(false)}>
                <Icon name="remove" />
                {' '}
                No
              </Button>
              <Button
                color="green"
                type="submit"
                inverted
                onClick={() => {
                  handleDelete();
                  setShowDeleteModal(false);
                  setShow(false);
                }}
              >
                <Icon name="checkmark" />
                {' '}
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </Modal>
      </Transition>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const {
    main: { customer },
    team: { selectedTeam },
  } = state;

  return { selectedTeam, customer };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  { saveTeammate, removeTeammate },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(TeammateForm);
