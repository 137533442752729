import { createUseStyles } from 'react-jss';

export default createUseStyles({
  teammateSegment: {
    marginTop: '0px !important',
    margin: 0,
    flex: 1,
    alignItems: 'center',
  },
  awardSegment: {
    marginTop: '0px !important',
    margin: 0,
    flex: 0,
    alignItems: 'center',
  },
  teammateForm: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  teammateName: {
    paddingLeft: 5,
    flex: 1,
  },
  teamMenuDiv: {
    flex: 0,
    height: '100%',
    margin: 0,
  },
  menu: {
    height: '100%',
    display: 'flex',
  },
  teamHeader: {
    flex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    height: '100%',
  },
  menuAdd: {
    backgroundImage: 'linear-gradient(to right, aliceblue, #e6f3ff) !important',
  },
  fullFlex: {
    display: 'flex',
    flex: 1,
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  noMargin: {
    margin: '0 !important',
  },
  activeTeam: {
    display: 'flex',
    margin: 0,
    justifyContent: 'flex-start',
  },
  headerContainer: {
    margin: '0px 0px 0px 10px !important',
    paddingLeft: 5,
    flex: 1,
  },
  flexEnd: {
    alignSelf: 'flex-end',
  },
});
