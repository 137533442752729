import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button, Card, Container, Divider, Icon, Input, Form, TextArea,
} from 'semantic-ui-react';
import Avatar from 'react-avatar';
import ImageUploading from 'react-images-uploading';
import MaskedInput from 'react-text-mask';
import { savePerson } from '../../../redux/actions/main';
import ChangePassword from '../../modals/changePassword';
import useStyles from './styles';

const Profile = ({
  person, savePerson,
}) => {
  const [images, setImages] = React.useState([]);
  const [newAvatar, setNewAvatar] = useState(undefined);
  const [avatar, setAvatar] = useState(person?.avatar);
  const maxNumber = 69;

  const [phone, setPhone] = useState(person?.phone);
  const [nickname, setNickname] = useState(person?.nickname);
  const [birthday, setBirthday] = useState(person?.birthday);
  const [hobby, setHobby] = useState(person?.hobby);
  const [webpage, setWebpage] = useState(person?.webpage);
  const [location, setLocation] = useState(person?.location);
  const [notes, setNotes] = useState(person?.notes);
  const [description, setDescription] = useState(person?.description);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const styles = useStyles();

  const onChange = (imageList) => {
    let newImageList = imageList;
    if (newImageList && newImageList.length > 1) {
      newImageList = newImageList.slice(newImageList.length - 1);
    }

    if (newImageList && newImageList.length > 0) {
      setImages(newImageList);
      setNewAvatar(newImageList[0].data_url);
    }
  };

  const onSave = () => {
    const newPerson = {
      ...person,
      phone,
      nickname,
      birthday,
      hobby,
      webpage,
      location,
      notes,
      description,
    };
    delete newPerson.createdDate;
    savePerson(newPerson, newAvatar);
  };

  const onPasswordChange = () => {
    setShowChangePasswordModal(true);
  };

  return (
    <Container className={styles.container}>
      <Card className={styles.card}>
        <Form>
          <div className={styles.formContainer}>
            <div className={styles.formWrapper}>
              <div>
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    onImageUpload,
                    onImageRemoveAll,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">

                      <div
                        className={styles.flex}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        <Avatar size={200} round src={newAvatar || avatar} name={person?.name} />
                        <div className={styles.iconWrapper}>
                          <Icon name="upload" inverted circular />
                        </div>
                      </div>
                      <div className={styles.uploadWrapper}>
                        <Icon
                          name="remove"
                          inverted
                          circular
                          onClick={() => {
                            onImageRemoveAll();
                            setNewAvatar('');
                            setImages([]);
                            setAvatar('');
                          }}
                        />
                      </div>

                    </div>
                  )}
                </ImageUploading>
              </div>
              <div className={styles.flexGrow}>
                <div className={styles.personWrapper}>
                  <div className={styles.flexGrow}>
                    {person?.name}
                  </div>
                  <div className={styles.buttonContainer}>
                    <div>
                      <Button onClick={onSave}>Save</Button>
                    </div>
                    <div className={styles.passwordChange}>
                      <Button onClick={onPasswordChange}>Change Password</Button>
                    </div>
                  </div>
                </div>
                <div width={16} className={styles.description}>{person?.description}</div>

                <div className={styles.inputContainer}>
                  <Form.Input
                    id="form-input-control-customer-email"
                    control={Input}
                    value={person?.email}
                    readOnly
                    label="Email Address"
                    placeholder="Email address..."
                  />
                  <Form.Input
                    label="Phone Number"
                    name="phone"
                    value={phone}

                    children={(
                      <MaskedInput
                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        placeholder="(123) 456-7890"
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <Divider />
            <div className={styles.formGroupContainer}>
              <Form.Group widths="equal">
                <Form.Input
                  id="form-input-control-customer-nickname"
                  control={Input}
                  value={nickname}
                  onChange={(e, d) => setNickname(d.value)}
                  label="Nickname"
                  placeholder="Nickname..."
                />
                <Form.Input
                  label="Birthday"
                  name="birthday"
                  value={birthday}
                  placeholder="01/01/2021"
                  onChange={(e, d) => setBirthday(d.value)}
                  type="date-local"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  id="form-input-control-customer-hobby"
                  control={Input}
                  value={hobby}
                  onChange={(e, d) => setHobby(d.value)}
                  label="Hobby"
                  placeholder="Hobby..."
                />
                <Form.Input
                  id="form-input-control-customer-webpage"
                  control={Input}
                  value={webpage}
                  onChange={(e, d) => setWebpage(d.value)}
                  label="Webpage"
                  placeholder="Webpage..."
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  id="form-input-control-customer-location"
                  control={Input}
                  value={location}
                  onChange={(e, d) => setLocation(d.value)}
                  label="Location"
                  placeholder="Location..."
                />
                <Form.Input
                  id="form-input-control-customer-notes"
                  control={Input}
                  value={notes}
                  onChange={(e, d) => setNotes(d.value)}
                  label="Notes"
                  placeholder="Notes..."
                />
              </Form.Group>
              <Form.Field
                id="form-input-control-auto-schedule-event-text"
                control={TextArea}
                rows={5}
                value={description}
                onChange={(e, data) => setDescription(data.value)}
                label="A little about yourself"
                placeholder="What would you like your teammates to know about you..."
              />

            </div>
          </div>
        </Form>
      </Card>
      <ChangePassword setShow={setShowChangePasswordModal} show={showChangePasswordModal} />

    </Container>
  );
};

const mapStateToProps = (state) => {
  const {
    main: { person },
  } = state;

  return { person };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  { savePerson },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
