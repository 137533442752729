import React, { useRef } from 'react';
import { Form } from 'semantic-ui-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SubscriptionContainer from '../containers/subscription';

const SubscriptionForm = ({
  setShow, show, currentProduct, stripeCustomer, products, prices,
}) => {
  const stripePromise = useRef(loadStripe('pk_test_51H87RGLjmskONUHlZ0qHIrmLP1KeFmq241sFBnWMrCvjiwEVWsqTA6wqsbXns2evv0Uua6IOJ9MUJ8pVnv7ak8GP00LjtWxk1A'));

  return (
    <Form>
      <Elements stripe={stripePromise.current}>
        <SubscriptionContainer
          setShow={setShow}
          show={show}
          currentProduct={currentProduct}
          stripeCustomer={stripeCustomer}
          products={products}
          prices={prices}
        />
      </Elements>
    </Form>
  );
};

export default SubscriptionForm;
