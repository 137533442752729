import React from 'react';
import { Item, Button } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import useStyles from './styles';

const TeamApproval = ({
  team, approveFunction,
}) => {
  const styles = useStyles;
  return (
    <Item.Group>
      <Item className={styles.item}>
        <div>
          <Button basic circular size="tiny" icon="checkmark" color="green" onClick={() => approveFunction(team.id, true)} />
        </div>
        <div>
          <Button basic circular size="tiny" icon="cancel" color="red" onClick={() => approveFunction(team.id, false)} />
        </div>
        <div className={styles.avatar} />
        <Avatar size={50} round src={team.avatar} name={team.name} />
        <div className={styles.header}>
          <Item.Header>{team.name}</Item.Header>
        </div>
      </Item>
    </Item.Group>
  );
};
export default TeamApproval;
