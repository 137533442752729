import { createUseStyles } from 'react-jss';

export default createUseStyles({
  center: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    opacity: 0.4,
  },
  flexCenter: {
    display: 'flex !important',
    justifyContent: 'center !important',
  },
  container: {
    width: '100% !important',
    height: '100% !important',
    maxHeight: '100%',
  },
  chartContainer: {
    padding: 10,
    paddingTop: 20,
    paddingBottom: 20,
    height: '100%',
  },
  liquidChart: {
    width: '100%',
    height: '50px',
  },
  centerAlign: {
    alignSelf: 'center',
  },
  flexNoGrow: {
    flex: '0 !important',
  },
  search: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 5,
    width: '100%',
  },
  fullWidth: {
    width: '100% !important',
  },
  fullHeight: {
    height: '100% !important',
  },
  leaderHeader: {
    alignSelf: 'center !important',
    margin: '0 !important',
    paddingLeft: '5px !important',
  },
  leaderCenter: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
    height: '80%',
    opacity: 0.4,
  },
  teammate: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  grid: {
    overflow: 'auto',
    maxHeight: 240,
  },
  popup: {
    position: 'relative',
    left: -5,
  },
  halfOpacity: {
    opacity: 0.5,
  },
  gridColumn: {
    marginTop: 5,
  },
  teamHeader: {
    paddingLeft: 5,
  },

});
