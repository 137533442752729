import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Icon, Modal, Header, Step, Button,
} from 'semantic-ui-react';
import _ from 'lodash';
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import SubscriptionSelection from '../forms/subscriptionSelection';
import SubscriptionPayment from '../forms/subscriptionPayment';
import SubscriptionInvoice from '../forms/subscriptionInvoice';
import { getCards, createSubscription, removeAllSubscriptionInformation } from '../../../redux/actions/payment';

const SubscriptionForm = ({
  show, products, stripeCustomer, prices, setShow, setBackDisabled, customer,
}) => {
  const [step, setStep] = useState(0);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(undefined);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [creditCard, setCreditCard] = useState(undefined);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(undefined);
  const [paymentError, setPaymentError] = useState(undefined);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setStep(0);
  }, [show]);

  useEffect(() => {
    const customerSubscription = _.get(stripeCustomer, 'data.subscriptions.data[0].plan.product', undefined);
    if (customerSubscription) {
      setSelectedSubscriptionId(customerSubscription);
    } else if (products) {
      const bestDeal = products.filter(a => a.metadata.bestDeal !== undefined);
      if (bestDeal) {
        setSelectedSubscriptionId(bestDeal[0].id);
      }
    }
  }, [stripeCustomer, products]);

  useEffect(() => {
    if (step === 0) {
      setNextDisabled(false);
    }
  }, [step]);

  const handleSubscriptionSelection = (a, b) => {
    setSelectedSubscriptionId(b.name);
  };

  const handleNextButton = async () => {
    if (step === 0) {
      setStep(1);
    } else if (step === 1) {
      try {
        setPaymentProcessing(true);
        setPaymentError(undefined);
        const cardElement = elements.getElement(CardNumberElement);
        await removeAllSubscriptionInformation(customer);
        const price = prices.filter(p => p.product === selectedSubscriptionId);
        if (price && price.length > 0) {
          const subscription = await createSubscription(customer, price[0].id);
          setCurrentSubscription(subscription);

          const payment = await stripe.confirmCardPayment(
            subscription.data.latest_invoice.payment_intent.client_secret, {
              payment_method: {
                card: cardElement,
              },
              setup_future_usage: 'off_session',
            },
          );

          if (payment.error) {
            setPaymentError(payment.error.message);
          } else {
            const cards = await getCards(customer);
            if (cards && cards.data && cards.data.length > 0) {
              setCreditCard(cards.data[0]);
            }
            setStep(2);
          }
        }
      } finally {
        setPaymentProcessing(false);
      }
    } else if (step === 2) {
      setStep(0);
      setSelectedSubscriptionId(undefined);
      setCurrentSubscription(undefined);
      setShow(false);
    }
  };

  return (
    <Modal
      closeIcon
      onClose={() => setShow(false)}
      onOpen={() => setShow(true)}
      open={show}
      closeOnDimmerClick={false}
    >
      <Header icon>
        <div style={{
          display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center',
        }}
        >
          <div>
            Subscription
          </div>
        </div>
      </Header>
      <Modal.Content style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
        <>
          <Step.Group>
            <Step active={step === 0}>
              <Icon name="shopping basket" />
              <Step.Content>
                <Step.Title>Subscription</Step.Title>
                <Step.Description>Pick a subscription</Step.Description>
              </Step.Content>
            </Step>

            <Step active={step === 1}>
              <Icon name="payment" />
              <Step.Content>
                <Step.Title>Billing</Step.Title>
                <Step.Description>Enter billing information</Step.Description>
              </Step.Content>
            </Step>

            <Step active={step === 2}>
              <Icon name="info" />
              <Step.Content>
                <Step.Title>Order Overview</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>
        </>
      </Modal.Content>
      <Modal.Content>
        {step === 0 && (
          <SubscriptionSelection
            handleSubscriptionSelection={handleSubscriptionSelection}
            subscription={selectedSubscriptionId}
            products={products}
          />
        )}
        {step === 1 && (
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <SubscriptionPayment
              setNextDisabled={setNextDisabled}
              subscription={selectedSubscriptionId}
              customer={customer}
              products={products}
              backDisabled={setBackDisabled}
              prices={prices}
              selectedSubscriptionId={selectedSubscriptionId}
              paymentError={paymentError}
            />
          </div>
        )}

        {step === 2 && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <SubscriptionInvoice
              subscription={currentSubscription}
              products={products}
              currentProductId={selectedSubscriptionId}
              creditCard={creditCard}
            />
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button.Group>
          {step === 1 && (
            <Button
              disabled={paymentProcessing}
              onClick={() => setStep(step - 1)}
            >
              <Icon name="angle left" />
              {' '}
              Back
            </Button>
          )}
          {step === 1 && (<Button.Or />)}
          <Button
            positive
            loading={paymentProcessing}
            disabled={nextDisabled}
            onClick={handleNextButton}
          >
            {step === 0 && (
              <span>
                Next
                {' '}
                <Icon name="angle right" />
                {' '}
              </span>
            )}
            {step === 1 && <span>Subscribe</span>}
            {step === 2 && <span>Close</span>}
          </Button>
        </Button.Group>
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const {
    main: { customer },
  } = state;

  return { customer };
};

export default connect(mapStateToProps, null)(SubscriptionForm);
