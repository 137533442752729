import moment from 'moment';
import timezones from './data/timezones';

const fields = {
  email: { options: ['E-mail Address', 'E-mail 1 - Value', 'E-mail 2 - Value', 'email', 'Email', 'EMail', 'E-mail', 'E-Mail', 'E-mail 2 Address', 'E-mail 3 Address'] },
  name: { options: [['First Name', 'Last Name'], 'Name', 'Display Name'] },
  avatar: { options: ['Photo', 'Avatar'] },
  phone: { options: ['Phone 1 - Value', 'Phone 2 - Value', 'Primary Phone', 'Mobile Phone', 'Home Phone', 'Home Phone 2', 'Business Phone', 'Business Phone 2', 'Other Phone'] },
  description: { options: ['Job Title'] },
  nickname: { options: ['Nickname'] },
  birthday: { options: ['Birthday'] },
  spouse: { options: ['Spouse'] },
  city: { options: ['Address 1 - City', 'Home City'] },
  country: { options: ['Address 1 - Country', 'Home Country/Region'] },
  street: { options: ['Address 1 - Street', 'Home Street'] },
  region: { options: ['Address 1 - Region', 'Home State'] },
  postalCode: { options: ['Address 1 - Postal Code', 'Home Postal Code'] },
  hobby: { options: ['Hobby'] },
  wepage: { options: ['Web Page', 'Personal Web Page', 'Website 1 - Value'] },
  notes: { options: ['Notes'] },
  location: { options: ['Location'] },
};

const convertFirebaseTimestampToDate = timestamp => moment(timestamp._seconds * 1000);

const getAllTimezones = () => timezones;

const buildOption = (options, row) => {
  let value = '';
  options.map(option => {
    if (!value) {
      if (Array.isArray(option)) {
        option.map(o => {
          if (row[o]) {
            value = `${value} ${row[o].trim()}`.trim();
          }
          return value;
        });
      } else {
        value = row[option];
      }
    }
    return value;
  });
  return value;
};

const parseContact = (type, row) => {
  const teammate = {};

  Object.keys(fields).map(field => {
    teammate[field] = buildOption(fields[field].options, row);
    return teammate;
  });
  return teammate;
};

export {
  convertFirebaseTimestampToDate,
  getAllTimezones,
  parseContact,
};
