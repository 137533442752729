import firebase from 'firebase';
import {
  GET_SCHEDULE, GET_EVENTS_SUCCESS, GET_EVENTS_REQUEST, UPDATE_EVENTS, SAVED_EVENT,
} from '../actionTypes';

export function getSchedule(data) {
  return { type: GET_SCHEDULE, payload: data };
}

export function getEvents(data) {
  return { type: GET_EVENTS_SUCCESS, payload: data };
}

export function requestEvents() {
  return { type: GET_EVENTS_REQUEST };
}

export function savedEvent(data) {
  return { type: SAVED_EVENT, payload: data };
}

export function eventsUpdatedOnServer() {
  return { type: UPDATE_EVENTS, payload: new Date() };
}

export function fetchEvents(teamId) {
  return dispatch => {
    dispatch(requestEvents());
    const callable = firebase.functions().httpsCallable('getEvents');
    callable(teamId).then(data => {
      dispatch(getEvents(data.data));
    });
  };
}

export function fetchEvent(eventId) {
  const callable = firebase.functions().httpsCallable('getEvent');
  return callable(eventId).then(data => data.data);
}

export function saveEvent(event) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('saveEvent');
    callable(event).then(() => {
      dispatch(fetchEvents(event.teamId));
      dispatch(savedEvent(event));
    });
  };
}

export function removeEvent(event) {
  return dispatch => {
    const callable = firebase.functions().httpsCallable('removeEvent');
    callable(event.id).then(() => {
      dispatch(fetchEvents(event.teamId));
    });
  };
}
