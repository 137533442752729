import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Sidebar, Icon, Image, Menu, Dropdown, Button, Search,
} from 'semantic-ui-react';
import Avatar from 'react-avatar';
import firebase from 'firebase';
import _ from 'lodash';
import { logoutUser, fetchCustomerById, startLoading } from './redux/actions/main';
import { setSelectedTeam } from './redux/actions/team';
import watercoolerImage from './styles/images/watercooler.png';
import watercoolerLogo from './styles/images/logo.png';
import './Main.css';

const Main = React.memo(({
  customer, myCustomers, myCustomer, person, selectedTeam, teams,
  currentUser, loading, loadingTeams, children, loadingTeammates,
  setSelectedTeam, fetchCustomerById, startLoading, logoutUser,
}) => {
  const contextRef = useRef();
  const [visible, setVisible] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(customer?.id);
  const [isOwner, setIsOwner] = useState(false);

  const history = useHistory();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        if (!history.location.pathname === '/signup' && !history.location.pathname === '/'
          && !history.location.pathname === '/login' && !history.location.pathname === '/join') {
          history.push('/');
        }
      }
    });
  }, [history]);

  useEffect(() => {
    if (customer?.id) {
      setSelectedCustomerId(customer.id);
    } else if (myCustomers && myCustomers.length > 0) {
      setSelectedCustomerId(myCustomers[0].id);
    }
  }, [myCustomers, customer, myCustomer]);

  useEffect(() => {
    if (!_.isEmpty(customer) && !_.isEmpty(person)) {
      setIsOwner(customer.email === person.email);
    }
  }, [customer, person]);

  const updateSidebar = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (!selectedTeam) {
      if (teams) {
        setSelectedTeam(teams[0]);
      } else {
        setSelectedTeam(undefined);
      }
    }
  }, [selectedTeam, teams, setSelectedTeam]);

  const updateCustomer = (value, data) => {
    fetchCustomerById(data.value);
    setSelectedTeam(null);
  };

  const updateTeam = (value, data) => {
    setSelectedTeam(teams?.find(team => team.id === data.value));
  };

  const buildOptions = () => {
    let options = [];

    if (myCustomers) {
      options = myCustomers.map(customer => ({
        key: customer.id,
        text: customer.companyName,
        value: customer.id,
        image:
  <>
    <Avatar size={25} round src={customer.avatar} name={customer.companyName} />
    {' '}
  </>,
      }));
    }
    return options;
  };

  const buildTeamOptions = () => {
    let options = [];

    if (teams) {
      options = teams.map(team => ({
        key: team.id,
        text: team.name,
        value: team.id,
        image: (
          <>
            <Avatar size={25} round src={team.avatar} name={team.name} />
            {' '}
          </>),

      }));
    }
    return options;
  };

  return (
    <div
      columns={1}
      style={{
        height: '100%', overflow: 'hidden', marginTop: 0, marginBottom: 0,
      }}
      data-tour="first-step"
    >
      {currentUser && !loading
        && (
        <div
          className="watercoolerNav"
          style={{
            display: 'flex', position: 'fixed', width: '100vw', alignItems: 'center', border: 1, height: 70,
          }}
        >
          <div style={{ flex: 0, marginLeft: 20, marginRight: 10 }}>
            <Button circular floated="right" icon="bars" size="medium" color="blue" onClick={() => setVisible(!visible)} />
          </div>
          <div style={{ display: 'flex', flex: 1 }}>
            <div style={{
              flex: 1, flexDirection: 'row', display: 'flex', justifyContent: 'flex-start', alignSelf: 'center',
            }}
            >
              <Image src={watercoolerImage} style={{ height: 40 }} />
              <Image src={watercoolerLogo} style={{ height: 40 }} />
            </div>
            <div style={{ alignSelf: 'center' }}><Search /></div>
            <div style={{
              flex: 1, display: 'flex', paddingRight: 50, alignSelf: 'center', justifyContent: 'flex-end',
            }}
            >
              <div style={{ alignSelf: 'center', justifyContent: 'space-between' }}>
                <Dropdown
                  style={{ padding: 20 }}
                  placeholder="Customer"
                  openOnFocus
                  loading={loadingTeams}
                  value={selectedCustomerId}
                  onChange={updateCustomer}
                  options={buildOptions()}
                />
                <Dropdown
                  placeholder="No team selected"
                  openOnFocus
                  loading={loadingTeammates}
                  value={selectedTeam?.id}
                  onChange={updateTeam}
                  options={buildTeamOptions()}
                />

              </div>
            </div>
            <div style={{ zIndex: 9999, paddingRight: 20, alignSelf: 'center' }} ref={contextRef}>
              <Avatar className="avatar" src={person?.avatar} name={person?.name} round size={40} onClick={() => setShowProfile(!showProfile)} />
            </div>
          </div>
        </div>
        )}
      <div style={{
        backgroundImage: 'linear-gradient(to right, aliceblue, #cce7ff)', height: '100vh', paddingTop: loading ? 0 : 70, paddingBottom: loading ? 0 : 20,
      }}
      >
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation="push"
            icon="labeled"
            inverted
            style={{ backgroundImage: 'linear-gradient(to right, #003666, #001b33 )' }}
            onHide={() => setVisible(false)}
            vertical
            visible={visible}
            width="thin"
          >
            <Menu.Item as={Link} to="/dashboard" onClick={updateSidebar}>
              <Icon name="home" />
              Home
            </Menu.Item>
            <Menu.Item as={Link} to="/events" onClick={updateSidebar}>
              <Icon name="calendar alternate outline" />
              Events
            </Menu.Item>
            {isOwner && (
            <Menu.Item as={Link} to="/teams" onClick={updateSidebar}>
              <Icon name="group" />
              Teams
            </Menu.Item>
            )}
            {isOwner && (
            <Menu.Item as={Link} to="/settings" onClick={updateSidebar}>
              <Icon name="settings" />
              Settings
            </Menu.Item>
            )}
          </Sidebar>

          <Sidebar
            as={Menu}
            animation="push"
            direction="right"
            inverted
            style={{ backgroundImage: 'linear-gradient(to right, #001b33, #003666  )' }}
            vertical
            visible={showProfile}
          >
            <Menu.Item
              as="a"
              header
              onClick={() => {
                setShowProfile(false);
                history.push('/profile');
              }}
            >
              Update your profile
            </Menu.Item>

            <Menu.Item
              as="a"
              header
              onClick={async () => {
                setShowProfile(false);
                startLoading();
                logoutUser();
              }}
            >
              Sign out of WaterCooler
            </Menu.Item>
            {customer && customer.admin && isOwner && (
            <Menu.Item
              as="a"
              header
              onClick={() => {
                setShowProfile(false);
                history.push('/admin');
              }}
            >
              Administration
            </Menu.Item>
            )}

          </Sidebar>

          <Sidebar.Pusher style={{ height: '100%' }}>
            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
        <div
          className="footer"
          style={{
            width: '100vw', display: 'flex', flex: 1, justifyContent: 'center',
          }}
        >
          Copyright &copy; 2022 WaterCooler, Inc.
        </div>
      </div>
    </div>
  );
});

const mapStateToProps = (state) => {
  const {
    main: {
      currentUser, customer, loading, person, myCustomers,
    },
    team: {
      teams, selectedTeam, loadingTeammates, loadingTeams,
    },
  } = state;

  return {
    currentUser,
    customer,
    loading,
    person,
    myCustomers,
    teams,
    selectedTeam,
    loadingTeammates,
    loadingTeams,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    logoutUser, startLoading, setSelectedTeam, fetchCustomerById,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Main);
