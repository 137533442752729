import initialState from '../initialState';
import {
  GET_SCHEDULE, GET_EVENTS_SUCCESS, GET_EVENTS_REQUEST, UPDATE_EVENTS, SAVED_EVENT,
} from '../actionTypes';

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SCHEDULE:
      return { ...state, schedule: action.payload };
    case GET_EVENTS_REQUEST:
      return { ...state, events: [], eventsProcessing: true };
    case GET_EVENTS_SUCCESS:
      return { ...state, events: action.payload, eventsProcessing: false };
    case UPDATE_EVENTS:
      return { ...state, updatedEventsDate: action.payload };
    case SAVED_EVENT:
      return { ...state, savedEvent: action.payload };
    default:
      return state;
  }
}
