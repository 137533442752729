import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import {
  Container, Segment, TextArea, Form, Button, Message,
} from 'semantic-ui-react';
import { fetchGlobalQuestions, saveGlobalQuestions } from '../../redux/actions/question';
import useStyles from './styles';

const Admin = ({
  customer,
}) => {
  const history = useHistory();
  const [questions, setQuestions] = useState('');
  const [questionMessage, setQuestionMessage] = useState('');

  const styles = useStyles();

  useEffect(() => {
    if (!customer || !customer.admin) {
      history.push('/login');
    }
  }, [customer, history]);

  const handleSave = async () => {
    const data = questions.split('\n');
    if (data && data.length > 0) {
      const globalQuestions = await
      (await fetchGlobalQuestions()).data.map(question => question.question.toLowerCase());

      let questionsToAdd = [];
      if (globalQuestions) {
        data.forEach(question => {
          const currentQuestion = question.trim();
          if (currentQuestion) {
            if (!globalQuestions.includes(currentQuestion.toLowerCase())) {
              questionsToAdd.push(currentQuestion);
            }
          }
        });
      } else {
        questionsToAdd = data;
      }
      if (questionsToAdd) {
        await saveGlobalQuestions(questionsToAdd);
        setQuestionMessage(`Questions saved: ${questionsToAdd.length}`);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Container>
          <Segment>
            <Form>
              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-auto-schedule-event-text"
                  control={TextArea}
                  rows={10}
                  value={questions}
                  onChange={(e, data) => setQuestions(data.value)}
                  label="Add Questions"
                  placeholder="Add additional questions that can be used for topics (each question on a new line)..."
                />
              </Form.Group>
              <Form.Field
                id="form-button-control-public"
                control={Button}
                content="Update"
                onClick={handleSave}
              />
            </Form>
            {questionMessage && (<Message>{questionMessage}</Message>)}
          </Segment>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    team: { teams, teammates, selectedTeam },
    schedule,
    settings: { settings },
    main: { currentUser, customer },
  } = state;

  return {
    teams, teammates, schedule, currentUser, customer, selectedTeam, settings,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  { fetchGlobalQuestions },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
