import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Icon, Modal, Header, Form, Image, Message,
} from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';
import { validateCustomer } from '../../../redux/actions/main';
import watercoolerImage from '../../../styles/images/logo.png';

const SignupForm = ({
  validateCustomer, validated,
}) => {
  const history = useHistory();
  const [message, setMessage] = useState('We are validating your account.');
  const [valid, setValid] = useState(undefined);
  const [messageHeader, setMessageHeader] = useState('Just one second');

  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const token = searchParams.get('token');
    const email = searchParams.get('email');

    if (token && email) {
      validateCustomer({ token, email });
    }
  }, [location, validateCustomer]);

  useEffect(() => {
    if (validated === true || validated === false) {
      setTimeout(() => {
        setValid(validated);
      }, 2000);
      // http://localhost:3006/validate?email=aaron.boss@gmail.com&token=NDJi4zX9yGuumiqhk1uf
    }
  }, [validated]);

  useEffect(() => {
    if (valid) {
      setMessageHeader('Success!!!');
      setMessage('All set! Please login to build your team...');
      setTimeout(() => {
        history.push('/login');
      }, 3000);
    } else if (valid === false) {
      setMessageHeader('Oh no!');
      setMessage("We couldn't confirm your account. Please contact support for help.");
    }
  }, [valid, history]);

  return (
    <Form>
      <Modal
        open
        basic
        dimmer
        size="mini"
      >
        <Header icon>
          <div style={{
            display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', color: 'white',
          }}
          >
            <div>
              <Image src={watercoolerImage} size="small" wrapped />
            </div>
          </div>
        </Header>
        <Message
          icon
          positive={valid}
          warning={!valid && valid !== undefined}
          info={valid === undefined}
          floating
        >
          {valid === undefined && <Icon name="circle notched" loading />}
          <Message.Content>
            <Message.Header>{messageHeader}</Message.Header>
            {message}
          </Message.Content>
        </Message>
      </Modal>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const {
    main: { validated },
  } = state;

  return { validated };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  { validateCustomer },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
