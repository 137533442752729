import { createUseStyles } from 'react-jss';

export default createUseStyles({
  item: {
    padding: 10,
    alignItems: 'center',
  },
  avatar: {
    width: 10,
  },
  header: {
    paddingLeft: 5,
  },
});
