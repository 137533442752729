import React from 'react';
import './approve.css';
import { Grid } from 'semantic-ui-react';
import ApprovalForm from '../modals/approve';
import WaterCoolerParticles from '../components/particles';
import useStyles from './styles';

const Validate = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <WaterCoolerParticles />
      <Grid columns={1} centered relaxed className={styles.grid}>
        <Grid.Column centered stretched textAlign="center" className={styles.column}>
          <ApprovalForm />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Validate;
