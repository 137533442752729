import moment from 'moment';
import {
  DRAWER_OPEN,
  DRAWER_CLOSE,
  GET_CUSTOMER,
  GET_PERSON,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
  RESET_CURRENT_USER,
  START_LOADING,
  FINISH_LOADING,
  FORGOT_PASSWORD_SUCCESS,
  FIND_MY_CUSTOMERS,
  VALIDATE_CUSTOMER,
} from '../actionTypes';

const initialState = {
  drawerOpen: false,
  currentUser: undefined,
  currentUserError: undefined,
  loading: true,
  customer: [],
  person: {},
  finishedTour: true,
  resetPasswordTime: null,
  validated: undefined,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FIND_MY_CUSTOMERS:
      return { ...state, myCustomers: action.payload };
    case START_LOADING:
      return { ...state, loading: true };
    case FINISH_LOADING:
      return { ...state, loading: false };
    case DRAWER_OPEN:
      return { ...state, drawerOpen: true };
    case DRAWER_CLOSE:
      return { ...state, drawerOpen: false };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, resetPasswordTime: moment().toDate() };
    case GET_CUSTOMER:
      return { ...state, customer: action.payload };
    case GET_PERSON:
      return { ...state, person: action.payload };
    case RESET_CURRENT_USER:
      return {
        ...state, currentUser: null, customer: [], person: null,
      };
    case USER_LOGIN_SUCCESS:
      return { ...state, currentUser: action.payload, currentUserError: undefined };
    case VALIDATE_CUSTOMER:
      return { ...state, validated: action.payload };
    case USER_LOGIN_FAILED:
      return { ...state, currentUser: undefined, currentUserError: action.payload };
    default:
      return state;
  }
}
