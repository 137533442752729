import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Menu, Container } from 'semantic-ui-react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { fetchEvent } from '../../../redux/actions/schedule';
import OrganizationSettings from './organization';
import EventSettings from './event';
import { fetchCustomer } from '../../../redux/actions/main';

const ORGANIZATION = 0;
const EVENT = 1;

const Settings = ({
  customer, person, settings, fetchSettings,
}) => {
  const [tab, setTab] = useState(ORGANIZATION);
  const history = useHistory();

  useEffect(() => {
    if (!_.isEmpty(customer) && !_.isEmpty(person)) {
      if (customer.email !== person.email) {
        history.push('/dashboard');
      }
    } else {
      history.push('/dashboard');
    }
  }, [customer, person, history]);

  useEffect(() => {
    if (_.isEmpty(settings) && !_.isEmpty(customer)) {
      fetchSettings(customer._id);
    }
  }, [settings, customer, fetchSettings]);

  return (
    <div>
      <Menu icon="labeled" style={{ backgroundImage: 'linear-gradient(to right, aliceblue, #cce7ff)' }}>
        <Menu.Item
          name="company"
          active={tab === ORGANIZATION}
          onClick={() => setTab(ORGANIZATION)}
        >
          <Icon name="building outline" />
          Organization
        </Menu.Item>

        <Menu.Item
          name="events"
          active={tab === EVENT}
          onClick={() => setTab(EVENT)}
        >
          <Icon name="calendar alternate outline" />
          Events
        </Menu.Item>
      </Menu>
      <Container>
        {tab === ORGANIZATION && (<div><OrganizationSettings /></div>)}
        {tab === EVENT && (<div><EventSettings settings={settings} /></div>)}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    team: { teams, teammates },
    schedule,
    settings,
    main: { currentUser, customer, person },
  } = state;

  return {
    settings, teams, teammates, schedule, currentUser, customer, person,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  { fetchEvent, fetchCustomer },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
