import firebase from 'firebase';

export async function getStripeCustomer(customer) {
  const callable = firebase.functions().httpsCallable('getStripeCustomer');
  try {
    return await callable(customer);
  } catch (e) {
    return undefined;
  }
}

export async function getProducts() {
  const callable = firebase.functions().httpsCallable('getProducts');
  try {
    return await callable();
  } catch (e) {
    return undefined;
  }
}

export async function getPrices() {
  const callable = firebase.functions().httpsCallable('getPrices');
  try {
    return await callable();
  } catch (e) {
    return undefined;
  }
}

export async function createSubscription(customerData, priceId) {
  const callable = firebase.functions().httpsCallable('createSubscription');
  try {
    return await callable({ customerData, priceId });
  } catch (e) {
    return undefined;
  }
}

export async function processSubscriptionPayment(customer, card) {
  const callable = firebase.functions().httpsCallable('processPayment');
  try {
    return await callable(customer, card);
  } catch (e) {
    return undefined;
  }
}

export async function getCards(customer) {
  const callable = firebase.functions().httpsCallable('getCards');
  try {
    const cards = await callable(customer);
    return cards;
  } catch (e) {
    return undefined;
  }
}

export async function getInvoice(invoiceId) {
  const callable = firebase.functions().httpsCallable('getInvoice');
  try {
    return await callable(invoiceId);
  } catch (e) {
    return undefined;
  }
}

export async function removeAllSubscriptionInformation(customer) {
  const callable = firebase.functions().httpsCallable('removeAllSubscriptionInformation');
  try {
    return await callable(customer);
  } catch (e) {
    return undefined;
  }
}

export async function removeCard(cardId) {
  const callable = firebase.functions().httpsCallable('removeCard');
  try {
    return await callable(cardId);
  } catch (e) {
    return undefined;
  }
}
