import React from 'react';
import { Grid, Button, Segment } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import Awards from '../Awards';
import useStyles from './styles';

const Profile = ({
  teammate, openTeammateForm,
}) => {
  const styles = useStyles();
  if (!teammate) {
    return <div />;
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <Avatar size={50} round src={teammate.avatar} name={teammate.name} />
        </div>
        <div className={styles.bodyContainer}>
          <div className={styles.nameContainer}>
            <div className={styles.name}>
              {teammate.name}
            </div>
            <div>
              <Button onClick={openTeammateForm}>Edit</Button>
            </div>
          </div>
          <div width={16} className={styles.description}>{teammate.description}</div>
          <Grid centered className={styles.gridWrapper}>
            <Grid.Row columns={2}>
              <Grid.Column width={2}>
                <div className={styles.spacer} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4} centered>
              <Grid.Column width={3} className={styles.item}>Email Address:</Grid.Column>
              <Grid.Column width={5}>{teammate.email}</Grid.Column>
              <Grid.Column width={3} className={styles.item}>Phone Number:</Grid.Column>
              <Grid.Column width={5}>{teammate.phoneNumber}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4} centered>
              <Grid.Column width={3} className={styles.item}>Nickname:</Grid.Column>
              <Grid.Column width={5}>{teammate.nickname || <i>Not specified</i>}</Grid.Column>
              <Grid.Column width={3} className={styles.item}>Birthday:</Grid.Column>
              <Grid.Column width={5}>{teammate.birthday || <i>Not specified</i>}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4} centered>
              <Grid.Column width={3} styleclassName={styles.item}>Hobbies:</Grid.Column>
              <Grid.Column width={5}>{teammate.hobbies || <i>Not specified</i>}</Grid.Column>
              <Grid.Column width={3} className={styles.item}>Webpage:</Grid.Column>
              <Grid.Column width={5}>{teammate.webpage || <i>Not specified</i>}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4} centered>
              <Grid.Column width={3} className={styles.item}>Location:</Grid.Column>
              <Grid.Column width={13}>{teammate.hobbies || <i>Not specified</i>}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4} centered>
              <Grid.Column width={16} className={styles.itemLeft}>Description:</Grid.Column>
              <Grid.Column width={16}>{teammate.description || <i>Not specified</i>}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4} centered>
              <Grid.Column width={16} className={styles.itemLeft}>Notes:</Grid.Column>
              <Grid.Column width={16}>{teammate.notes || <i>Not specified</i>}</Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
      <div className={styles.awardContainer}>
        <div className={styles.segmentWrapper}>
          <Segment className={styles.segment}>
            <Awards pencil />
          </Segment>
        </div>
      </div>
    </div>
  );
};

export default Profile;
