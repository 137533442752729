import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Icon, Button, Modal, Header, Form, Popup, Menu, Transition,
} from 'semantic-ui-react';
import Avatar from 'react-avatar';
import _ from 'lodash';
import ImageUploading from 'react-images-uploading';
import { saveTeam } from '../../../redux/actions/team';
import '../styles/teams.css';

const timeLimit = 200;

const TeamModal = ({
  show, team, customer, saveTeam, setShow, onCancel, title,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [name, setName] = useState('');
  const [avatar, setAvatar] = useState('');
  const [newAvatar, setNewAvatar] = useState(undefined);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (show) {
      setModalShow(show);
    } else {
      setTimeout(() => {
        setModalShow(show);
      }, timeLimit - 10);
    }
  }, [show]);

  useEffect(() => {
    if (!_.isEmpty(team)) {
      setName(team.name);
      setAvatar(team.avatar);
    } else {
      setName('');
      setAvatar('');
      setNewAvatar('');
    }
  }, [team]);

  const handleSubmit = async () => {
    const data = { name, avatar };
    if (team?.id) {
      data.id = team.id;
    }
    data.customerId = customer.id;
    await saveTeam(data, newAvatar);
    setShow(false);
  };

  const onChange = (il) => {
    let imageList = il;
    if (imageList && imageList.length > 1) {
      imageList = imageList.slice(imageList.length - 1);
    }

    if (imageList && imageList.length > 0) {
      setImages(imageList);
      setNewAvatar(imageList[0].data_url);
    }
  };

  return (
    <Form>
      <Transition visible={show} animation="fade" duration={{ hide: timeLimit, show: timeLimit }}>
        <Modal
          onClose={() => {
            setShow(false);
            if (onCancel) {
              onCancel();
            }
          }}
          onOpen={() => setShow(true)}
          open={modalShow}
          basic
          size="small"
          closeOnDimmerClick={false}
        >
          <Header icon>
            <div style={{
              display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center',
            }}
            >
              <div>
                {team?.id ? 'Update Team Infomation' : title || 'Add a new team'}
              </div>
            </div>
          </Header>
          <Modal.Content style={{ display: 'flex', flex: 1 }}>
            <Form style={{ flex: 1 }} onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Field style={{ display: 'flex', alignItems: 'center' }}>
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={100}
                    dataURLKey="data_url"
                  >
                    {({
                      onImageUpload,
                      onImageRemoveAll,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">

                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                          {...dragProps}
                        >
                          <div style={{
                            display: 'flex', id: 'hi!', alignSelf: 'flex-end', zIndex: 9996,
                          }}
                          >
                            <Avatar size={50} round src={newAvatar || avatar} name={name} />
                            <div style={{
                              position: 'relative', alignSelf: 'flex-start', left: 0, bottom: 0,
                            }}
                            >
                              <Popup
                                style={{ zIndex: 9999 }}
                                position="bottom left"
                                trigger={
                                  <Icon name="ellipsis vertical" />
                                }
                                content={(
                                  <Menu icon vertical style={{ zIndex: 9999 }}>
                                    <Menu.Item
                                      name="gamepad"
                                      onClick={onImageUpload}
                                    >
                                      <Icon name="upload" />
                                    </Menu.Item>

                                    <Menu.Item
                                      name="remove"
                                      onClick={() => {
                                        onImageRemoveAll();
                                        setNewAvatar('');
                                        setImages([]);
                                        setAvatar('');
                                      }}
                                    >
                                      <Icon name="remove" />
                                    </Menu.Item>
                                  </Menu>
                                )}
                                on="click"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                </Form.Field>
                <Form.Input
                  width={16}
                  label="Name"
                  name="name"
                  value={name}
                  required
                  onChange={(e, d) => setName(d.value)}
                  placeholder="name"
                />
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              color="red"
              onClick={() => {
                setShow(false);
                if (onCancel) {
                  onCancel();
                }
              }}
            >
              <Icon name="remove" />
              {' '}
              Cancel
            </Button>
            <Button color="green" type="submit" onClick={handleSubmit}>
              <Icon name="checkmark" />
              {' '}
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </Form>
  );
};

const mapStateToProps = state => {
  const {
    main: { customer },
  } = state;

  return { customer };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  { saveTeam },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(TeamModal);
