import initialState from '../initialState';
import {
  GET_TEAMMATES,
  REQUEST_TEAMMATES,
  REQUEST_TEAMS,
  GET_TEAMS,
  GET_UNAPPROVED_TEAMS,
  SET_SELECTED_TEAM,
  UPDATE_TEAMMATE_ANSWER,
  UPDATE_HAS_ACCOUNT,
  UPDATE_HAS_TEAMS,
} from '../actionTypes';

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_TEAMMATES:
      return { ...state, loadingTeammates: true };

    case REQUEST_TEAMS:
      return { ...state, loadingTeams: true };

    case GET_TEAMMATES:
      return { ...state, teammates: action.payload, loadingTeammates: false };

    case GET_TEAMS:
      return { ...state, teams: action.payload, loadingTeams: false };

    case SET_SELECTED_TEAM:
      return { ...state, selectedTeam: action.payload };

    case GET_UNAPPROVED_TEAMS:
      return { ...state, unapprovedTeams: action.payload };

    case UPDATE_TEAMMATE_ANSWER:
      return { ...state, teammateAnswer: action.payload };

    case UPDATE_HAS_ACCOUNT:
      return { ...state, hasAccount: action.payload };

    case UPDATE_HAS_TEAMS:
      return { ...state, hasTeams: action.payload };

    default:
      return state;
  }
}
