export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';

export const SET_SELECTED_TEAM = 'SET_SELECTED_TEAM';
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const GET_PERSON = 'GET_PERSON';
export const FIND_MY_CUSTOMERS = 'FIND_MY_CUSTOMERS';

export const DRAWER_OPEN = 'main/DRAWER_OPEN';
export const DRAWER_CLOSE = 'main/DRAWER_CLOSE';

export const GET_TEAMMATES = 'GET_TEAMMATES';
export const REQUEST_TEAMMATES = 'REQUEST_TEAMMATES';
export const GET_TEAMS = 'GET_TEAMS';
export const REQUEST_TEAMS = 'REQUEST_TEAMS';
export const GET_UNAPPROVED_TEAMS = 'GET_UNAPPROVED_TEAMS';

export const GET_SCHEDULE = 'GET_SCHEDULE';

export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE';

export const UPDATE_EVENTS = 'UPDATE_EVENTS';
export const SAVED_EVENT = 'SAVED_EVENT';

export const RESET_CURRENT_USER = 'RESET_CURRENT_USER';

export const GET_QUESTIONS = 'GET_QUESTIONS';

export const GET_SETTINGS = 'GET_SETTINGS';

export const START_LOADING = 'START_LOADING';
export const FINISH_LOADING = 'FINISH_LOADING';

export const FINISH_TOUR = 'FINISH_TOUR';

export const VALIDATE_CUSTOMER = 'VALIDATE_CUSTOMER';

export const NEW_VALIDATION_EMAIL = 'NEW_VALIDATION_EMAIL';

export const UPDATE_TEAMMATE_ANSWER = 'UPDATE_TEAMMATE_ANSWER';
export const UPDATE_HAS_ACCOUNT = 'UPDATE_HAS_ACCOUNT';
export const UPDATE_HAS_TEAMS = 'UPDATE_HAS_TEAMS';
