import React from 'react';
import './validate.css';
import { Grid } from 'semantic-ui-react';
import ValidateForm from '../modals/validate';
import WaterCoolerParticles from '../components/particles';

const Validate = React.memo(() => (
  <div style={{ height: '110vh', backgroundImage: 'linear-gradient(to right, #99cfff, #66b8ff)' }}>
    <WaterCoolerParticles />
    <Grid columns={1} centered relaxed style={{ backgroundImage: 'linear-gradient(to right, #99cfff, #66b8ff)' }}>
      <Grid.Column centered stretched textAlign="center" style={{ paddingTop: 200 }}>
        <ValidateForm />
      </Grid.Column>
    </Grid>
  </div>
));

export default Validate;
