import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  menu: {
    backgroundImage: 'linear-gradient(to right, aliceblue, #e6f3ff)',
  },
});
