import React, { useState, useEffect } from 'react';
import {
  Card, Button, Input, Dimmer,
} from 'semantic-ui-react';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import EventForm from '../../modals/event';
import useStyles from '../teams/styles';

const EventsCard = React.memo(({
  loading, events, isOwner, teammates, schedule,
}) => {
  const [showEventModal, setShowEventModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [eventFilter, setEventFilter] = useState(null);

  const styles = useStyles();

  useEffect(() => {
    if (currentEvent) {
      setShowEventModal(true);
    }
  }, [currentEvent]);

  const handleEventClick = async (data) => {
    setCurrentEvent({ ...schedule.events.find(e => e.id === data.event.id) });
  };

  return (
    <Dimmer.Dimmable as={Card} dimmed={loading} blurring elevation={3} className={styles.container}>
      <Card.Content className={styles.flexNoGrow}>
        {isOwner && (
        <Button
          circular
          floated="right"
          size="mini"
          color="blue"
          icon="add"
          data-tour="event-add-step"
          disabled={!teammates || teammates.length === 0}
          onClick={() => {
            setCurrentEvent({});
          }}
        />
        )}
        <Card.Header>Events</Card.Header>
      </Card.Content>
      <div className={styles.search}>
        <Input transparent icon="search" className={styles.fullWidth} placeholder="Search..." onChange={(e) => setEventFilter(e.target.value)} />
      </div>
      <Card.Content>
        <FullCalendar
          height="100%"
          plugins={[listPlugin, interactionPlugin]}
          initialView="listMonth"
          events={eventFilter
            ? events.filter(e => e?.title?.toLowerCase()
              .includes(eventFilter.toLowerCase())) : events}
          eventClick={handleEventClick}
        />
      </Card.Content>
      <EventForm setShow={setShowEventModal} show={showEventModal} event={currentEvent} />
    </Dimmer.Dimmable>
  );
});

export default EventsCard;
