import initialState from '../initialState';
import { GET_SETTINGS } from '../actionTypes';

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SETTINGS:
      return { ...state, settings: action.payload };

    default:
      return state;
  }
}
