import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card, Table, Header, Icon, Transition,
} from 'semantic-ui-react';
import LiquidChart from 'react-liquidchart';
import Avatar from 'react-avatar';
import _ from 'lodash';
import { buildLeaderboard } from '../../../redux/actions/reports';
import useStyles from '../teams/styles';

const mockLeaders = [{
  name: 'Aaron',
  participationCount: 400,
}, {
  name: 'Bill Rowland',
  participationCount: 380,
}, {
  name: 'Steve',
  participationCount: 300,
}];

const LeaderBoardCard = ({
  selectedTeam,
}) => {
  const [leaders, setLeaders] = useState(mockLeaders);
  const [eventCount, setEventCount] = useState(450);

  const styles = useStyles();

  useEffect(() => {
    if (selectedTeam) {
      buildLeaderboard(selectedTeam.id).then(json => {
        const { data } = json;
        if (data.eventCount > 0) {
          setEventCount(data.eventCount);
          setLeaders(data.leaders);
        }
      });
    }
  }, [selectedTeam]);

  const getPercentage = leader => (leader.participationCount / eventCount) * 100;

  return (
    <Card elevation={3} className={styles.container}>
      <Card.Content header="Leader Board" />

      <Transition visible animation="scale" duration={{ hide: 10, show: 500 }}>
        <Card.Content className={styles.fullHeight}>
          {leaders && leaders.length > 0 && (
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell singleLine>Rating</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" singleLine>Teammate</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" singleLine>Participation</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {leaders.map((leader, rating) => (
                  <Table.Row key={`leaderboard_${leader.name}`}>
                    <Table.Cell>
                      <Header as="h4" textAlign="center">
                        {rating === 0 && <Icon color="yellow" name="trophy" />}
                        {rating !== 0 && <div>{rating + 1}</div>}
                      </Header>
                    </Table.Cell>
                    <Table.Cell textAlign="center" singleLine>
                      <div className={styles.flexCenter}>
                        <Avatar size={30} round src={leader.avatar} name={leader.name} />
                        <Header as="h3" className={styles.leaderHeader}>{leader.name}</Header>
                      </div>
                    </Table.Cell>
                    <Table.Cell collapsing singleLine textAlign="center">
                      <div className={styles.liquidChart}>
                        <LiquidChart
                          responsive
                          value={getPercentage(leader)}
                          showDecimal
                          amplitude={4}
                          frequency={2}
                          animationTime={2000}
                          animationWavesTime={2250}
                          gradient={{
                            type: 1,
                            x1: 0,
                            x2: 100,
                            y1: 100,
                            y2: 0,
                          }}
                          postfix="%"
                        />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
          {_.isEmpty(leaders) && (
            <div className={styles.leaderCenter}>
              <div className={styles.centerAlign}>
                <Icon name="trophy" size="large" circular />
              </div>
              <div className={styles.centerAlign}>
                There is no data to build the leaderboard
              </div>
            </div>
          )}
        </Card.Content>
      </Transition>
    </Card>
  );
};

const mapStateToProps = state => {
  const {
    team: { selectedTeam },
  } = state;

  return {
    selectedTeam,
  };
};

export default connect(mapStateToProps, null)(LeaderBoardCard);
