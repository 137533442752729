import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Icon } from 'semantic-ui-react';
import Chart from 'react-apexcharts';
import { buildOverviewReportData } from '../../../redux/actions/reports';
import useStyles from '../teams/styles';

const ChartsCard = ({
  selectedTeam,
}) => {
  const [reportData, setReportData] = useState([]);
  const [options, setOptions] = useState(undefined);
  const [series, setSeries] = useState(undefined);
  const [eventCount, setEventCount] = useState(0);

  const styles = useStyles();
  // {
  //   chart: {
  //     height: 350,
  //     type: 'area'
  //   },
  //   dataLabels: {
  //     enabled: false
  //   },
  //   stroke: {
  //     curve: 'smooth'
  //   },
  //   xaxis: {
  //     type: 'datetime',
  //     categories: [reportData.categories]
  //   },
  //   tooltip: {
  //     x: {
  //       format: 'dd/MM/yy HH:mm'
  //     },
  //   },
  // },

  useEffect(() => {
    if (selectedTeam) {
      buildOverviewReportData(selectedTeam.id).then(json => {
        setReportData(json.data);
      });
    } else {
      setReportData(null);
      setSeries(null);
      setOptions(null);
      setEventCount(0);
    }
  }, [selectedTeam]);

  useEffect(() => {
    if (reportData) {
      const invitations = reportData.series?.find(p => p.name === 'Invitations');
      setSeries(reportData.series);
      setEventCount(reportData.categories?.length);
      setOptions(
        {
          chart: {
            id: 'watercooler-engagement',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: reportData.categories,
          },
          yaxis: {
            labels: {
              formatter(val) {
                return val.toFixed(0);
              },
            },
            decimalsInFloat: 0,
            tickAmount: 1,
            forceNiceScale: true,
            min: 0,
            max: invitations && invitations.data ? Math.max.apply(null, invitations.data) : 1,
          },
        },
      );
    }
  }, [reportData]);

  return (
    <Card fluid elevation={3} className={styles.container}>
      <div className={styles.chartContainer}>
        {!!(options && series && eventCount) && <Chart options={options} series={series} type="area" height="100%" width="100%" />}
        {!!(!series || !eventCount) && (
          <div className={styles.center}>
            <div className={styles.centerAlign}>
              <Icon name="area chart" size="large" circular />
            </div>
            <div className={styles.centerAlign}>
              There is no statistical data to build the participation chart
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const {
    team: { selectedTeam },
  } = state;

  return {
    selectedTeam,
  };
};

export default connect(mapStateToProps, null)(ChartsCard);
