import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Icon, Input, Dimmer, Button, Card, Grid, Popup, Item,
} from 'semantic-ui-react';
import Avatar from 'react-avatar';
import TeammateForm from '../../modals/teammate';
import TeamForm from '../../modals/team';
import useStyles from './styles';

const TeamsCard = React.memo(({
  teammates, loading, isOwner, selectedTeam,
}) => {
  const [showAddTeammateModal, setShowAddTeammateModal] = useState(false);
  const [showAddTeamModal, setShowAddTeamModal] = useState(false);
  const [teamInfo, setTeamInfo] = useState(null);
  const [teammateInfo, setTeammateInfo] = useState(null);
  const [teammateFilter, setTeammateFilter] = useState(null);
  const [visible, setVisible] = useState(false);

  const styles = useStyles();

  useEffect(() => {
    setVisible(false);
    setTimeout(() => setVisible(true), 5);
  }, [teammates]);

  useEffect(() => {
    if (teammateInfo) {
      setShowAddTeammateModal(true);
    }
  }, [teammateInfo]);

  useEffect(() => {
    if (teamInfo) {
      setShowAddTeamModal(true);
    }
  }, [teamInfo]);

  const openTeammateForm = (data) => {
    setTeammateInfo(data);
  };

  const updateShowTeammateModal = show => {
    if (!show) {
      setTeammateInfo(null);
    }
    setShowAddTeammateModal(show);
  };

  const updateShowTeamModal = show => {
    if (!show) {
      setTeamInfo(null);
    }
    setShowAddTeamModal(show);
  };

  return (
    <div className={styles.fullWidth}>
      <Dimmer.Dimmable
        as={Card}
        dimmed={loading}
        blurring
        elevation={3}
        className={styles.container}
      >
        <Card.Content className={styles.flexNoGrow}>
          {isOwner && <Button circular floated="right" disabled={!selectedTeam} icon="add" size="mini" color="blue" data-tour="click-teammate-step" onClick={() => openTeammateForm({})} />}
          <Card.Header>Teammates</Card.Header>
        </Card.Content>
        {selectedTeam && (
          <div className={styles.search}>
            <Input transparent icon="search" className={styles.fullWidth} placeholder="Search..." onChange={(e) => setTeammateFilter(e.target.value)} />
          </div>
        )}
        <Card.Content className={styles.teammate}>
          {(!teammates || teammates.length === 0) && visible && selectedTeam && (
            <div className={styles.leaderCenter}>
              <div className={styles.centerAlign}>
                <Icon name="group" size="large" circular />
              </div>
              <div className={styles.centerAlign}>
                There are no teammates for this team
              </div>
            </div>
          )}
          {!selectedTeam && (
            <div className={styles.leaderCenter}>
              <div className={styles.centerAlign}>
                <Icon name="group" size="large" circular />
              </div>
              <div className={styles.centerAlign}>
                No team has been created
              </div>
            </div>
          )}

          <Grid columns="2" stretched className={styles.grid}>
            <Grid.Row>
              {teammates && teammates.filter(teammate => {
                if (teammateFilter) {
                  return teammate?.name?.indexOf(
                    teammateFilter,
                  ) > -1 || teammate?.description?.indexOf(teammateFilter) > -1;
                }
                return true;
              }).map(teammate => (
                <Grid.Column className={styles.gridColumn} key={`teammate_${teammate.id}`}>
                  <Item.Group>
                    <Item>
                      <Avatar size={50} round src={teammate.avatar} name={teammate.name}><Icon name="check" corner /></Avatar>
                      {teammate.approved
                        && (
                        <Popup
                          content={`${teammate.name} has approved membership`}
                          key={`approval_${teammate.id}`}
                          trigger={<Icon name="checkmark" corner="top left" size="small" className={styles.popup} color="green" />}
                        />
                        )}
                      {!teammate.approved
                        && (
                        <Popup
                          content={`${teammate.name} has not yet approved membership`}
                          key={`approval_${teammate.id}`}
                          trigger={<Icon name="clock" corner="top left" size="small" className={styles.popup} color="yellow" />}
                        />
                        )}
                      <div className={styles.teamHeader}>
                        <Item.Header as={isOwner ? 'a' : 'div'} onClick={() => (isOwner ? openTeammateForm(teammate) : null)}>
                          {teammate.name}
                        </Item.Header>
                        <Item.Description>
                          {teammate.description}
                          <div className={styles.halfOpacity}>
                            {teammate.email}
                          </div>
                        </Item.Description>
                      </div>
                    </Item>
                  </Item.Group>
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        </Card.Content>
        {selectedTeam && (
          <Card.Content extra>
            <Icon name="user" />
            Team members:
            {' '}
            {teammates ? teammates.filter(t => t.approved).length : 0}
            {' '}
            active /
            {' '}
            {teammates ? teammates.filter(t => !t.approved).length : 0}
            {' '}
            pending
          </Card.Content>
        )}
      </Dimmer.Dimmable>
      <TeammateForm
        setShow={updateShowTeammateModal}
        show={showAddTeammateModal}
        teammateInfo={teammateInfo}
      />
      <TeamForm setShow={updateShowTeamModal} show={showAddTeamModal} team={teamInfo} />
    </div>
  );
});

const mapStateToProps = (state) => {
  const {
    team: { teams, selectedTeam },
  } = state;

  return { teams, selectedTeam };
};

export default connect(mapStateToProps, null)(TeamsCard);
