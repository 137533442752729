import React from 'react';
import './signup.css';
import { Grid } from 'semantic-ui-react';
import SignupForm from '../modals/signup';
import WaterCoolerParticles from '../components/particles';

const Signup = React.memo(() => (
  <div style={{ height: '110vh', backgroundImage: 'linear-gradient(to right, #99cfff, #66b8ff)' }}>
    <WaterCoolerParticles />
    <Grid columns={1} centered relaxed style={{ backgroundImage: 'linear-gradient(to right, #99cfff, #66b8ff)' }}>
      <Grid.Column centered stretched textAlign="center" style={{ paddingTop: 200 }}>
        <SignupForm />
      </Grid.Column>
    </Grid>
  </div>
));

export default Signup;
