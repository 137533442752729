import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Dashboard,
  Events,
  Settings,
  Login,
  Signup,
  Admin,
  ForgotPassword,
  Teams,
  Profile,
  Join,
  Validate,
  Approve,
} from './views';
import { openDrawer, closeDrawer } from './redux/actions/main';
import withAuthProtection from './api/withAuthentication';

const ProtectedDashboardProfile = withAuthProtection('/login')(Dashboard);
const ProtectedSettingsProfile = withAuthProtection('/login')(Settings);
const ProtectedEventsProfile = withAuthProtection('/login')(Events);
const ProtectedAdminProfile = withAuthProtection('/login')(Admin);
const ProtectedTeamsProfile = withAuthProtection('/login')(Teams);
const ProtectedProfileProfile = withAuthProtection('/login')(Profile);

const Routes = props => {
  const { currentUser } = props;

  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/login" component={Login} />
      <Route path="/forgotpassword" component={ForgotPassword} />
      <Route path="/signup" component={Signup} />
      <Route path="/join" component={Join} />
      <Route path="/validate" component={Validate} />
      <Route path="/teamstatus" component={Approve} />
      <Route
        exact
        path="/dashboard"
        render={props => (
          <ProtectedDashboardProfile me={user} {...props} />
        )}
      />
      <Route
        exact
        path="/settings"
        render={props => (
          <ProtectedSettingsProfile me={user} {...props} />
        )}
      />
      <Route
        exact
        path="/admin"
        render={props => (
          <ProtectedAdminProfile me={user} {...props} />
        )}
      />
      <Route
        exact
        path="/events"
        render={props => (
          <ProtectedEventsProfile me={user} {...props} />
        )}
      />
      <Route
        exact
        path="/profile"
        render={props => (
          <ProtectedProfileProfile me={user} {...props} />
        )}
      />
      <Route
        exact
        path="/teams"
        render={props => (
          <ProtectedTeamsProfile me={user} {...props} />
        )}
      />
    </Switch>
  );
};

const mapStateToProps = (state) => {
  const {
    main: { currentUser },
  } = state;

  return { currentUser };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  { openDrawer, closeDrawer },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
