import React from 'react';
import { Image, Popup, Header } from 'semantic-ui-react';
import AwardImages, { awardData } from './images';
import useStyles from './styles';

// const awards = [
//   'bullseye',
//   'chatbubble',
//   'check',
//   'clock',
//   'crown',
//   'fire',
//   'flag',
//   'gem',
//   'globe',
//   'heart',
//   'hourglass',
//   'key',
//   'lightbulb',
//   'lock',
//   'musicnotes',
//   'pencil',
//   'question',
//   'star',
//   'suitcase',
//   'trophy'
// ]

const Awards = awards => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      {awardData.map(award => {
        if (awards[award.name]) {
          const content = (
            <div className={styles.content}>
              <Header>{award.header}</Header>
              <Image src={AwardImages.achieved[award.name]} size="small" />
              <div>
                {award.text}
              </div>
            </div>
          );

          return (
            <div key={award.name} className={styles.popup}>
              <Popup
                content={content}
                key={award.name}
                trigger={<Image circular className={styles.img} src={AwardImages.achieved[award.name]} size="small" />}
              />
            </div>
          );
        }
        const content = (
          <div className={styles.content}>
            <Header>{award.header}</Header>
            <Image src={AwardImages.achieved[award.name]} size="small" />
            <div>
              {award.unachievedText}
            </div>
          </div>
        );

        return (
          <div key={award.name} className={styles.popup}>
            <Popup
              content={content}
              key={award.name}
              trigger={<Image circular disabled src={AwardImages.unachieved[award.name]} size="small" />}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Awards;
