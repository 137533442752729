import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    height: '100%',
    padding: 20,
  },
  card: {
    height: '100%',
    width: '100%',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: 40,
  },
  iconWrapper: {
    position: 'relative',
    bottom: 10,
    alignSelf: 'flex-end',
    right: 135,
  },
  uploadWrapper: {
    position: 'absolute',
    alignSelf: 'flex-end',
    left: 145,
    top: 202,
  },
  flex: {
    display: 'flex',
  },
  flexGrow: {
    flex: 1,
  },
  personWrapper: {
    display: 'flex',
    fontSize: 24,
    fontWeight: '800',
    paddingTop: 20,
    paddingLeft: 20,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  passwordChange: {
    paddingTop: 5,
  },
  description: {
    fontSize: 16,
    fontWeight: '400',
    paddingLeft: 25,
  },
  inputContainer: {
    paddingTop: 50,
  },
  formGroupContainer: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
  },
});
