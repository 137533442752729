import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nameContainer: {
    marginLeft: 5,
  },
  personName: {
    margin: 0,
  },
  personEmail: {
    fontSize: 12,
    opacity: 0.5,
  },
});
